import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import moment from 'moment';
import { duration } from 'moment/moment';
import { formatDateToDDMMYYYY } from '../../pages/utils/helper';
import BasicButton from '../../pages/components/BasicButton';
import CalendarIcon from '../../pages/assets/calendar.png';

export const DateRangeSelector = ({ dateRange, setDateRange }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    const clickListener = (e) => {
      setTimeout(() => {
        // timeout needed so button press still works
        const classNameContainsRdp = (
          e.target.nearestViewportElement?.className?.baseVal ??
          e.target.className?.baseVal ??
          e.target.className
        ).includes('rdp');
        if (
          e.target.id !== `keepDropdownOpenCalendar` &&
          !classNameContainsRdp
        ) {
          setDropdownVisible(false);
        }
      }, 100);
    };
    document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, []);

  let numberOfDays = 0;
  if (dateRange?.to) {
    numberOfDays = duration(moment(dateRange.to).diff(dateRange.from)).as(
      'days',
    );
    numberOfDays = Math.round(numberOfDays + 1); // duration is not inclusive of start date
  } else if (dateRange?.from) numberOfDays = 1;

  return (
    <div className="d-flex flex-row justify-content-between">
      <label className="flex-one">Added on</label>
      <div
        className="d-flex flex-column add-user-input align-items-end position-relative"
        id="keepDropdownOpenCalendar"
      >
        <button
          className="rounded-borders-and-padding d-flex justify-content-between"
          onClick={() => setDropdownVisible(!isDropdownVisible)}
          id="keepDropdownOpenCalendar"
        >
          <div id="keepDropdownOpenCalendar">
            {dateRange?.from
              ? `${formatDateToDDMMYYYY(dateRange.from)} - `
              : ''}
            {formatDateToDDMMYYYY(dateRange?.to)}
          </div>
          <Image
            src={CalendarIcon}
            alt="down"
            id="keepDropdownOpenCalendar"
            height={20}
          />
        </button>

        {isDropdownVisible && (
          <div
            className="hover-card calendar-position width-310px"
            id="keepDropdownOpenCalendar"
          >
            <div className="p-3 pb-0">
              <b>Select a date</b>
            </div>
            <DayPicker
              mode="range"
              onSelect={setDateRange}
              selected={dateRange}
              id="keepDropdownOpenCalendar"
            />
            <div className="border-top m-3 pt-3 d-flex gap-2">
              <div className="flex-fill">
                {numberOfDays
                  ? `${numberOfDays} day${numberOfDays === 1 ? '' : 's'}`
                  : ''}{' '}
              </div>
              <BasicButton
                outlined
                onClick={() => setDateRange()}
                id="keepDropdownOpenCalendar"
              >
                Clear
              </BasicButton>
              <BasicButton>Apply</BasicButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
