import { FunctionComponent, useMemo } from 'react';
import { PieChart, Pie, Label, Cell } from 'recharts';

type DonutProgressProps = {
  values: {
    primary: number;
    secondary: number;
    tertiary?: number;
  };
  colorScheme: {
    primary: string;
    secondary: string;
    tertiary?: string;
  };
  textContent?: string;
  large?: boolean;
  small?: boolean;
  inShadow?: boolean;
};

const DonutProgress: FunctionComponent<DonutProgressProps> = (
  props: DonutProgressProps,
) => {
  const donutProgressData = useMemo(() => {
    return [
      {
        name: 'primary',
        value: props.values.primary,
        fill: props.colorScheme.primary,
      },
      {
        name: 'secondary',
        value: props.values.secondary,
        fill: props.colorScheme.secondary,
      },
      ...(props.values.tertiary
        ? [
            {
              name: 'tertiary',
              value: props.values.tertiary,
              fill: props.colorScheme.tertiary,
            },
          ]
        : []),
    ];
  }, [props]);

  return (
    <PieChart
      width={props.large ? 220 : props.small ? 140 : 160}
      height={props.large ? 220 : props.small ? 140 : 160}
    >
      <Pie
        data={donutProgressData}
        dataKey="value"
        cx={props.large ? 100 : props.small ? 65 : 80}
        cy={props.large ? 100 : props.small ? 65 : 80}
        outerRadius={props.large ? 100 : props.small ? 60 : 70}
        innerRadius={props.large ? 65 : props.small ? 40 : 45}
        startAngle={90}
        endAngle={-270}
      >
        {props.textContent && (
          <Label
            value={`${props.textContent}`}
            position="center"
            color={'black'}
            fontWeight={'bold'}
            fontSize={props.large ? '14px' : props.small ? '10px' : '12px'}
          />
        )}
      </Pie>
      {props.inShadow && (
        <Pie
          data={donutProgressData}
          dataKey="value"
          cx={props.large ? 100 : props.small ? 65 : 80}
          cy={props.large ? 100 : props.small ? 65 : 80}
          outerRadius={props.large ? 75 : props.small ? 46 : 52}
          innerRadius={props.large ? 65 : props.small ? 40 : 45}
          startAngle={90}
          endAngle={-270}
        >
          {donutProgressData.map((_, index) => (
            <Cell key={`cell-${index}`} fill={'#00000040'} />
          ))}
        </Pie>
      )}
    </PieChart>
  );
};

export default DonutProgress;
