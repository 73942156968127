import { FunctionComponent, useEffect, useState } from 'react';
import styles from './cards.module.css';
import { EngineStatus, TruckType } from '../model';
import { convertMinutesToHHMM, formatAsNDigitNumber } from '../helper';
import Dmu from '../../pages/assets/dmu-container.png';
import { useAssetContext } from '../../pages/service/assetContext';
import useMediaQuery from '../../hooks/useMediaQuery';
import { maxHeightBreakPoint } from '../constants';
import BulldozerSelected from '../assets/BulldozerSelected';
import Bulldozer from '../assets/Bulldozer';

type DisplayCardProps = {
  handleTruckClick: Function;
  status: boolean;
  type: TruckType;
  totalTonnesProcessed?: number;
  totalPush?: number;
  activeTime: number;
  speed?: number;
  name: string;
  isSelected: boolean;
};

const getAssetImages = (type: TruckType, selected: boolean, small: boolean) => {
  if (type === TruckType.DMU) {
    return <img src={Dmu} alt="unit-img" />;
  } else if (selected) {
    return <BulldozerSelected height={small ? 45 : 50} />;
  } else {
    return <Bulldozer height={small ? 40 : 50} />;
  }
};

const DisplayCard: FunctionComponent<DisplayCardProps> = ({
  handleTruckClick,
  type,
  totalTonnesProcessed,
  name,
  speed,
  totalPush,
  activeTime,
  status,
  isSelected,
}: DisplayCardProps) => {
  const {
    currentSpeedLive,
    pushesLive,
    engineHoursLive,
    engineStatusLive,
    tonnageLive,
  } = useAssetContext();
  const [speedState, setSpeedState] = useState<number>(speed ?? 0);
  const [pushesState, setPushesState] = useState<number>(totalPush ?? 0);
  const [activeTimeState, setActiveTimeState] = useState<number>(activeTime);
  const [engineStatusState, setEngineStatusState] = useState<EngineStatus>(
    status ? EngineStatus.ACTIVE : EngineStatus.INACTIVE,
  );
  const [tonnage, setTonnage] = useState<number>(totalTonnesProcessed ?? 0);

  useEffect(() => {
    if (
      currentSpeedLive?.value !== undefined &&
      currentSpeedLive.truckName === name
    ) {
      console.log('updating speed card');
      setSpeedState(currentSpeedLive.value);
    }
    if (
      engineStatusLive?.value !== undefined &&
      engineStatusLive?.truckName === name
    ) {
      console.log('updating status card');
      setEngineStatusState(engineStatusLive.value);
    }
    if (pushesLive?.value !== undefined && pushesLive.truckName === name) {
      console.log('updating pushes card');
      setPushesState(pushesLive.value);
    }
    if (
      engineHoursLive?.value !== undefined &&
      engineHoursLive.truckName === name
    ) {
      console.log('updating activetime card');
      setActiveTimeState(engineHoursLive.value);
    }
    if (tonnageLive?.value !== undefined && tonnageLive.truckName === name) {
      console.log('updating tonnageLive card');
      setTonnage(tonnageLive.value);
    }
  }, [
    currentSpeedLive,
    engineStatusLive,
    pushesLive,
    engineHoursLive,
    tonnageLive,
  ]);

  const { height } = useMediaQuery();

  return (
    <div
      className={styles.truckCardWrap}
      style={{
        cursor: type === TruckType.DMU ? 'not-allowed' : 'pointer',
        minWidth:
          type === TruckType.DMU
            ? height <= maxHeightBreakPoint
              ? '220px'
              : '260px'
            : height <= maxHeightBreakPoint
            ? '290px'
            : '320px',
        maxWidth:
          type === TruckType.DMU
            ? height <= maxHeightBreakPoint
              ? '240px'
              : '300px'
            : height <= maxHeightBreakPoint
            ? '310px'
            : '360px',
      }}
      onClick={() => handleTruckClick(type, name)}
    >
      <div
        className={
          isSelected ? styles.truckCardTxtSelected : styles.truckCardTxt
        }
      >
        <div style={{ display: 'flex', gap: '8px' }}>
          <span className={styles.title}>{name}</span>{' '}
          <span
            className={styles.status}
            style={{
              color: engineStatusState ? '' : 'red',
              fontWeight: engineStatusState ? '' : 'bold',
            }}
          >
            ({engineStatusState ?? 'Status NA'})
          </span>
        </div>
        {type === TruckType.DMU ? (
          <div
            style={{ width: '130px', display: 'flex', flexDirection: 'column' }}
          >
            <span className={styles.label}>
              Total tonnes <br /> processed:
            </span>{' '}
            <span className={styles.value}>{tonnage} t</span>
          </div>
        ) : (
          <>
            <div className={styles.dozerData}>
              <span className={styles.label}>Total pushes:</span>{' '}
              <span className={styles.value}>{pushesState}</span>
            </div>
            <div style={{ width: '150px' }} className="d-flex">
              <div style={{ width: '75px' }} className={styles.dozerData}>
                <span className={styles.label}>Active Time:</span>{' '}
                <span className={styles.value}>
                  {convertMinutesToHHMM(activeTimeState ?? 0) + ' h'}
                </span>
              </div>
              <div style={{ width: '75px' }} className={styles.dozerData}>
                <span className={styles.label}>Current Speed:</span>{' '}
                <span className={styles.value}>
                  {formatAsNDigitNumber(speedState ?? 0, 2) + ' km/h'}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className={isSelected ? styles.imgSelected : styles.img}
        style={{
          background:
            isSelected && engineStatusState === EngineStatus.ACTIVE
              ? '#99EDB3'
              : engineStatusState === EngineStatus.ACTIVE
              ? '#CBEFD6'
              : '#E8E7E7',
        }}
      >
        {getAssetImages(type, isSelected, height <= maxHeightBreakPoint)}
      </div>
    </div>
  );
};

export default DisplayCard;
