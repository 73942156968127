import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import moment from 'moment';
import left from '../../assets/left-arrow.png';
import right from '../../assets/right-arrow.png';
import calendar from '../../assets/calendar.png';
import download from '../../assets/download.png';
import CustomDatePicker from '../components/CustomDatePicker';
import styles from '../summary/Summary.module.css';
import {
  areSameDate,
  currentDateNZ,
  getFirstAndLastDayOfMonth,
  getStartTime,
  isFullMonth,
  joinWithAnd,
  minDate,
} from '../helper';
import { useAssetContext } from '../../pages/service/assetContext';
import MultiAssetSelect from './MultiAssetSelect';
import { SelectedAsset } from '../model';

type Props = {
  finalRange: DateRange | undefined;
  setFinalRange: Function;
  xAxisValueSelect: string | undefined;
  setxAxisValueSelect: Function;
};

const SummaryHeader = ({
  finalRange,
  setFinalRange,
  xAxisValueSelect,
}: Props) => {
  const [range, setRange] = useState<DateRange | undefined>({
    from: new Date(),
  });
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [wholeMonth, setWholeMonth] = useState<boolean>(false);
  const { selectedAssets, setSelectedAssets, assets } = useAssetContext();

  useEffect(() => {
    if (xAxisValueSelect) {
      //Handles the click from the graph, stores the month in MMMM, YYYY format or date in DD/MM/YYYY format
      if (moment(xAxisValueSelect, 'DD/MM/YYYY').isValid()) {
        //True when a date is selected from the graph
        const date: Date = moment(xAxisValueSelect, 'DD/MM/YYYY').toDate();
        if (moment(date).isSameOrBefore(new Date())) {
          setRange({
            from: moment(xAxisValueSelect, 'DD/MM/YYYY').toDate(),
            to: undefined,
          });
          setFinalRange({
            from: moment(xAxisValueSelect, 'DD/MM/YYYY').toDate(),
            to: undefined,
          });
          setWholeMonth(false);
        }
      } else if (moment(xAxisValueSelect, 'MMMM, YYYY').isValid()) {
        //True when a month is selected from the graph
        const date: Date = moment(xAxisValueSelect, 'MMMM, YYYY').toDate();
        if (moment(date).isSameOrBefore(new Date())) {
          const tempRange: DateRange = getFirstAndLastDayOfMonth(date); //gets the 1st day and last day of the month in date to get the month's date range
          if (moment(tempRange.to).isSame(new Date())) {
            //sets wholemonth as false for current month as whenever it is current month, we are selecting a range instead of whole month as whole month might contain future dates
            setRange(tempRange);
            setFinalRange(tempRange);
            setWholeMonth(false);
          } else {
            setRange(tempRange);
            setFinalRange(tempRange);
            setWholeMonth(true);
          }
        }
      }
    }
  }, [xAxisValueSelect]);

  const handleLeft = (): void => {
    const newFromDate: moment.Moment = moment(finalRange?.from).subtract(
      1,
      'day',
    );

    // Check if the new from date is before the start date, if so, return early
    if (newFromDate.isBefore(minDate)) {
      return;
    }

    if (wholeMonth) {
      // If whole month is selected from picker, it subtracts 1 day from the startDate of the range which is the 1st day of the month,
      // After subtracting 1 day, it results into last day of the previous month,
      // Finally that updated date is passed in the helper function to get the 1st day and last day of the month from the resulting date
      setFinalRange(getFirstAndLastDayOfMonth(newFromDate.toDate()));
      return;
    }

    setFinalRange({
      ...finalRange,
      from: newFromDate.toDate(),
    });

    setRange({
      ...finalRange,
      from: newFromDate.toDate(),
    });
  };

  const handleRight = (): void => {
    if (wholeMonth) {
      // if whole month is selected from picker, it adds 1 day from the endDate of the range which is the last day of the month, after adding 1 day, it results into first day of the next month, finally that updated date is passed in the helper function to get the 1st day and last day of the month from the resulting date
      if (
        getStartTime(finalRange?.to ?? new Date()) === getStartTime(new Date())
      ) {
        //Skipping updation by checking if it is the current month as we cannot selected future dates. It checks it by comparing the endDate from the selectedRange and current date
        return;
      }
      setFinalRange(
        getFirstAndLastDayOfMonth(
          moment(finalRange?.to)
            .add(1, 'day')
            .toDate(),
        ),
      );
      return;
    }
    if (finalRange?.to) {
      //If a range is already selected, it starts adding days to the endDate of the range
      if (areSameDate(finalRange.to, currentDateNZ)) return;
      setFinalRange({
        ...finalRange,
        to: moment(finalRange?.to)
          .add(1, 'day')
          .toDate(),
      });
      setRange({
        ...finalRange,
        to: moment(finalRange?.to)
          .add(1, 'day')
          .toDate(),
      });
    } else if (finalRange?.from) {
      //If a single date is selected, it starts adding days to it
      if (areSameDate(finalRange.from, currentDateNZ)) return;

      setFinalRange({
        from: moment(finalRange?.from)
          .add(1, 'day')
          .toDate(),
        to: undefined,
      });
      setRange({
        from: moment(finalRange?.from)
          .add(1, 'day')
          .toDate(),
        to: undefined,
      });
    }
  };

  useEffect(() => {
    if (finalRange) {
      setRange({ ...finalRange });
      if (wholeMonth && !isFullMonth(range)) {
        //If date selected changes and whole month is already selected, it checks if it is a full month range, if it returns false, it sets wholeMonth as false as the range is not a complete month
        setWholeMonth(false);
      }
    }
  }, [finalRange]);

  const handleMultiAssetApply = (values: SelectedAsset[]): void => {
    setSelectedAssets(values);
  };

  return (
    <div className={styles.summaryHeader}>
      <div className={styles.datePicker}>
        {showDatePicker && (
          <CustomDatePicker
            range={range}
            setRange={setRange}
            finalRange={finalRange}
            setFinalRange={setFinalRange}
            setShowDatePicker={setShowDatePicker}
            wholeMonth={wholeMonth}
            setWholeMonth={setWholeMonth}
          />
        )}
        <div className={styles.dateButton}>
          <div
            onClick={() => setShowDatePicker(true)}
            className={styles.modalToggle}
          >
            <img src={calendar} alt="" />
            Dates
          </div>
          <div className={styles.modeToggle}>
            <div className={styles.toggle} onClick={handleLeft}>
              <img src={left} alt="" />
            </div>
            <div
              className={styles.dateDisplay}
              onClick={() => setShowDatePicker(true)}
            >
              {wholeMonth
                ? moment(range?.from).format('MMMM')
                : finalRange
                ? finalRange.from && finalRange.to
                  ? `${moment(finalRange.from).format('DD/MM/YYYY')} - ${moment(
                      finalRange.to,
                    ).format('DD/MM/YYYY')}`
                  : moment(finalRange.from).format('DD/MM/YYYY')
                : ''}
            </div>
            <div className={styles.toggle} onClick={handleRight}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>

        <MultiAssetSelect
          assets={assets}
          selectedAssets={selectedAssets}
          handleApply={handleMultiAssetApply}
        />
      </div>
      <div className={styles.headerRight}>
        <div className={styles.dateInfo}>
          Displaying data for {joinWithAnd(selectedAssets, 'name')}{' '}
          {finalRange && finalRange?.to ? 'from' : 'for'}{' '}
          {finalRange && moment(finalRange?.from).format('DD/MM/YYYY')}{' '}
          {finalRange &&
            finalRange?.to &&
            ' - ' + moment(finalRange.to).format('DD/MM/YYYY')}
        </div>
        <div className={styles.download}>
          <img src={download} alt="" /> Download
        </div>
      </div>
    </div>
  );
};

export default SummaryHeader;
