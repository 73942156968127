import { Modal } from 'react-bootstrap';
import BasicButton from '../pages/components/BasicButton';
import {
  UserActionDropdown,
  dropdownOptions,
} from '../pages/components/UserActionsDropdown';
import StatusToggle from '../pages/components/StatusToggle';
const UserModal = ({
  modalOpen,
  onSaveClick,
  modalToggle,
  children,
  headerText,
  backdrop,
  hideHeaderButton,
  onEditClick,
  closeButton,
  selectedUser,
  handleDropdownOptions, // view user action menu onClick
}) => {
  return (
    <div>
      {modalOpen && (
        <Modal
          backdropClassName={!backdrop ? 'modal-backgrop-style' : ''}
          show={modalOpen}
          onHide={modalToggle}
          backdrop={backdrop ? 'static' : true}
          keyboard={false}
          size="lg"
          dialogClassName="modal-position"
        >
          <Modal.Header closeButton={closeButton}>
            <div className="modal-header-container">
              {hideHeaderButton ? (
                <BasicButton
                  outlined
                  onClick={() =>
                    handleDropdownOptions(dropdownOptions.edit, selectedUser)
                  }
                >
                  Edit
                </BasicButton>
              ) : (
                <label className="hazard-label">
                  <b>{headerText}</b>
                </label>
              )}
              {hideHeaderButton ? (
                <div className="d-flex gap-3 me-4">
                  {selectedUser?.status !== 'Invited' && (
                    <StatusToggle
                      isActive={selectedUser?.status === 'Active'}
                      onChange={() =>
                        handleDropdownOptions(
                          dropdownOptions.changestatus,
                          selectedUser,
                        )
                      }
                    />
                  )}
                  <UserActionDropdown
                    selectedUser={selectedUser}
                    handleDropdownOptions={handleDropdownOptions}
                  />
                </div>
              ) : (
                <div className="d-flex gap-2 button-container">
                  <BasicButton
                    outlined
                    onClick={modalToggle}
                    variant="outline-dark"
                  >
                    Discard
                  </BasicButton>
                  <BasicButton onClick={onSaveClick} variant="dark">
                    Save
                  </BasicButton>
                </div>
              )}
            </div>
          </Modal.Header>
          <Modal.Body>{children}</Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default UserModal;
