import { Form } from 'react-bootstrap';
export const FormModal = ({ header, children }) => (
  <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center px-2">
    <div
      onClick={(e) => e.stopPropagation()}
      className="P-Update-Success-2-change-password rounded-2 position-relative"
    >
      {header ? (
        <h4
          style={{ fontWeight: 'bold', fontSize: '16px' }}
          className="mt-4 mb-2"
        >
          {header}
        </h4>
      ) : null}

      <Form className="my-2 d-flex flex-column align-items-center">
        {children}
      </Form>
    </div>
  </div>
);
