import { useEffect, useState } from 'react';
import { getAuthToken } from '../dmu/services';

export const WebSocketURL = process.env.REACT_APP_NZSTEEL_WEBSOCKET_URI!;

export const useSocket = <SocketResponse>(
  url: String,
  handleResponseCallback: (responseData: SocketResponse) => void,
  clientName?: string,
) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    const connect = (url: string): WebSocket => {
      const newSocket = new WebSocket(url);
      setSocket(newSocket);
      newSocket.onerror = (error: Event) => {
        console.error('WebSocket error:', error);
      };
      return newSocket;
    };

    let socketUrl = WebSocketURL + url;
    if (clientName) socketUrl += `&clientName=${clientName}`;
    socketUrl += `&Authorization=${getAuthToken()}`;
    let createdSocket: WebSocket = connect(socketUrl);
    let heartbeatInterval: NodeJS.Timeout = setInterval(() => {
      if (createdSocket.readyState === WebSocket.OPEN) {
        createdSocket.send('heartbeat');
      } else {
        createdSocket = connect(socketUrl);
      }
    }, 25000);

    return () => {
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }
      createdSocket.close();
    };
  }, [clientName, url]);

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event: MessageEvent) => {
        const message = event?.data;
        const responseData: SocketResponse = JSON.parse(message);
        handleResponseCallback(responseData);
      };
    }
  }, [handleResponseCallback, socket]);
};
