import { Button } from 'react-bootstrap';

const BasicButton = ({
  outlined,
  children,
  onClick,
  size,
  name,
  disabled,
  id,
}) => {
  return (
    <Button
      disabled={disabled}
      name={name}
      size={size}
      onClick={onClick}
      className="rounded-1 px-2 basic-btn"
      variant={outlined ? 'outline-dark' : 'dark'}
      id={id}
    >
      <div
        className="basic-btn-text"
        style={{ width: children.length > 9 && '130px' }}
        name={name}
        id={id}
      >
        {children}
      </div>
    </Button>
  );
};

export default BasicButton;
