import React from 'react';

interface LoadingSpinnerProps {
  width?: string | number;
  height?: string | number;
  containerWidth?: string | number;
  containerHeight?: string | number;
  transparent?: boolean;
}

const LoaderSpinner: React.FC<LoadingSpinnerProps> = ({
  width = 30,
  height = 30,
  containerHeight = 50,
  containerWidth = 50,
  transparent,
}) => {
  return (
    <div
      style={{
        height: containerHeight,
        width: containerWidth,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        width={width}
        height={height}
        style={{
          shapeRendering: 'auto',
          display: 'block',
          background: `${transparent ? 'transparent' : 'rgb(255, 255, 255)'}`,
        }}
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g>
          <circle
            strokeDasharray="164.93361431346415 56.97787143782138"
            r="35"
            strokeWidth="10"
            stroke="#000000"
            fill="none"
            cy="50"
            cx="50"
          >
            <animateTransform
              keyTimes="0;1"
              values="0 50 50;360 50 50"
              dur="1s"
              repeatCount="indefinite"
              type="rotate"
              attributeName="transform"
            />
          </circle>
          <g></g>
        </g>
      </svg>
    </div>
  );
};

export default LoaderSpinner;
