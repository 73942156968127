// @ts-nocheck
import { useState, FunctionComponent } from 'react';
import { categoryDefects } from '../../displayCategoryDefectsSummary';
import { SummaryWidgetProps } from '../../helper/types';
import {
  DefectColor,
  PreCheckEvaluationResultLabels,
} from '../../helper/constants';
import DisplaySummaryPieChart from './displaySummaryPieChart';
import { includesPassOrIncomplete } from '../../helper';

const SummaryWidget: FunctionComponent<SummaryWidgetProps> = (props) => {
  const [isCategoryViewOpen, setIsCategoryViewOpen] = useState<boolean>(true);

  const toggleCategoryView = (): void => {
    setIsCategoryViewOpen((prevState: Boolean) => !prevState);
  };

  return (
    <div className="prestartcheck-summary-container triangle-div p-3 d-flex flex-column ">
      <div className="header d-flex justify-content-between align-items-start py-0 ">
        <span className="fw-bold ">SUMMARY</span>
        <div type="button" onClick={toggleCategoryView} className="fw-bold">
          {isCategoryViewOpen ? '>' : '<'}
        </div>
      </div>
      <div className="body d-flex justify-content-between h-100 gap-lg-5 gap-md-3 gap-sm-2 ">
        {isCategoryViewOpen ? (
          <>
            {categoryDefects.map(({ Icon, name }) => (
              <div className=" d-flex flex-column justify-content-evenly">
                {includesPassOrIncomplete(name) ? (
                  <Icon size={'50'} />
                ) : (
                  <Icon size={'30.82'} />
                )}
                <div
                  style={{
                    ...(includesPassOrIncomplete(name) && {
                      marginBottom: '0.5rem',
                    }),
                  }}
                >
                  <h1
                    className="fw-bold "
                    style={{
                      color: DefectColor[name],
                    }}
                  >
                    {props.summary[name]}
                  </h1>
                  <div>
                    <span className="fw-bold text-size text-capitalize">
                      {PreCheckEvaluationResultLabels[name]}
                    </span>
                    <div className="text-size">
                      {includesPassOrIncomplete(name) ? 'Checks' : 'Failures'}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <DisplaySummaryPieChart
              showLabel={props.showLabel}
              summary={props.summary}
            />
          </>
        ) : (
          <DisplaySummaryPieChart
            showKey={true}
            showLabel={true}
            summary={props.summary}
          />
        )}
      </div>
    </div>
  );
};

export default SummaryWidget;
