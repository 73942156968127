import { useState } from 'react';
import Card from 'react-bootstrap/Card';

// toggleHeaderModal, setHeaderZoneModal]

function ProfileModal({ setVisible }) {
  const [modalShow, setModalShow] = useState({
    editProfile: false,
    editPassword: false,
    viewProfile: false,
    editPin: false,
    viewPin: false,
  });

  return (
    <div className="profile-div">
      <Card
        style={{ zindex: '999' }}
        className="text-left py-2"
        id="profileCard"
      >
        <button
          className="hover-style py-1 px-4 border-0"
          onClick={() => {
            localStorage.clear();
            window.location.href = `/`;
          }}
        >
          Log out
        </button>
      </Card>
    </div>
  );
}

export default ProfileModal;
