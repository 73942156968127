import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import ArrowDown from '../assets/arrowDown.png';
import SearchIcon from '../assets/Search.svg';
import { convertCodeListToNames } from '../utils/helper';

const DropdownField = ({
  name, // name displayed on form
  fieldKey, // accessor key for values/filter object
  selectedOptions, // will be an array if multiselect is true, else will be the value
  allOptions, // {value: string | number, name: string}[]
  multiselect, // boolean
  handleChange,
  upperLevelClassName = 'd-flex flex-row justify-content-between field-container',
  lowerLevelClassName = '',
  errorMessage,
  categoryText,
}) => {
  const [isDropdownVisible, setDropdown] = useState(false);
  const [dropdownSearch, setDropdownSearch] = useState('');

  const nameWithoutWhiteSpace = name ? name.split(' ').join('') : '';
  const allChosenOptions = multiselect
    ? allOptions.filter(({ value }) => selectedOptions.includes(value))
    : []; // doesn't include selectedOptions that are not displayed to user (i.e. hidden department)

  useEffect(() => {
    const clickListener = (e) => {
      if (e.target.id !== `keepDropdownOpen${nameWithoutWhiteSpace}`) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [nameWithoutWhiteSpace]);

  const selectAll = () => {
    if (allChosenOptions.length === allOptions.length) {
      // deselect all
      allOptions.map(({ value }) => handleChange(fieldKey, value, true, true));
    } else {
      // select any that are not currently selected
      allOptions
        .filter(({ value }) => !selectedOptions.includes(value))
        .map(({ value }) => handleChange(fieldKey, value, true, true));
    }
  };

  return (
    <div className={upperLevelClassName}>
      {name ? <label className="flex-one">{name}</label> : null}

      <div className="d-flex flex-column add-user-input">
        <div className="position-relative">
          {/* open dropdown field */}
          <button
            className={`rounded-borders-and-padding ${lowerLevelClassName}`}
            id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            type="button"
            onClick={() => {
              setDropdown(!isDropdownVisible);
            }}
          >
            <div
              className="d-flex justify-content-between px-1"
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            >
              {multiselect
                ? convertCodeListToNames(
                    allOptions,
                    selectedOptions,
                    categoryText,
                  )
                : allOptions.find(({ value }) => value === selectedOptions)
                    ?.name}
              <div id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                <Image
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  src={ArrowDown}
                  className="mx-2"
                  alt="down"
                />
              </div>
            </div>
          </button>
          {isDropdownVisible && (
            <div
              className="hover-card dropdown-position"
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            >
              {/* Search field */}
              <div className="border-bottom p-2">
                <img
                  src={SearchIcon}
                  alt="search"
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                />
                <input
                  placeholder="Search"
                  className="no-focus-border border border-0 ps-1 w-75"
                  onChange={({ target }) => setDropdownSearch(target.value)}
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  value={dropdownSearch}
                />
              </div>

              {/* select all checkbox */}
              {multiselect && !dropdownSearch && (
                <div
                  className="option-row"
                  onClick={selectAll}
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                >
                  {multiselect && (
                    <input
                      type="checkbox"
                      checked={allChosenOptions.length === allOptions.length}
                      readOnly
                      id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                    />
                  )}
                  <label id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                    All
                  </label>
                </div>
              )}

              {/* dropdown options */}
              {allOptions
                .filter(({ name }) =>
                  name.toLowerCase().includes(dropdownSearch.toLowerCase()),
                )
                .map(({ name: optionName, value: optionValue }) => (
                  <div
                    className="option-row"
                    key={optionValue}
                    onClick={() => {
                      handleChange(fieldKey, optionValue, multiselect);
                      if (!multiselect) setDropdown(false);
                    }}
                    id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  >
                    {multiselect && (
                      <input
                        type="checkbox"
                        checked={selectedOptions.includes(optionValue)}
                        readOnly
                        id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                      />
                    )}
                    <label id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                      {optionName}
                    </label>
                  </div>
                ))}
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="error-msg-manage-operator">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default DropdownField;
