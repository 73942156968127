import { useContext } from 'react';
import { UserContext } from '../../service/userContext';
import FloatingInput from '../../components/FloatingInput';
import BasicButton from '../../components/BasicButton';
import { FormModal } from '../../components/FormModal';
const ViewPin = ({ setModalShow, setVisible }) => {
  const { userInfo } = useContext(UserContext);
  return (
    <FormModal header="Your PIN number">
      <FloatingInput
        disabled
        style={{ width: '100%' }}
        value={userInfo.pin_number}
        label="Current PIN"
        type="text"
      />
      <div className="d-flex gap-2 justify-content-end w-100 mt-3 text-center">
        <BasicButton onClick={() => setVisible(false)} outlined>
          Cancel
        </BasicButton>
        <BasicButton
          variant="outline-dark"
          onClick={() => setModalShow({ editPin: true })}
          outlined
        >
          Edit
        </BasicButton>
      </div>
    </FormModal>
  );
};

export default ViewPin;
