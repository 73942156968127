import React, { FunctionComponent, useEffect, useState } from 'react';
import { getSortedData, isPrestartSelected } from '../../helper';
import messages from '../../../constants/messages';
import {
  SortedDataByCategory,
  SortedDataByDepartment,
  TableProps,
} from '../../helper/types';
import { LabelRow } from './LabelRow';
import { PrestartRow } from './PrestartRow';
import {
  ExtendedPrestartCheck,
  PrestartCheckForTable,
} from '../../../pages/utils/types';
import { isArray } from 'lodash';
import { SortBy } from '../../models/enums';

const TableComponent: FunctionComponent<TableProps> = ({
  prestartChecks = [],
  sortBy = SortBy.DEPARTMENT,
  onRowClick = () => {},
  showCharts,
  selectedPrestartId,
}) => {
  const [sortedData, setSortedData] = useState<
    SortedDataByCategory | SortedDataByDepartment
  >({});

  useEffect(() => {
    const prestartChecksSortedByDeptOrCategory:
      | SortedDataByCategory
      | SortedDataByDepartment
      | undefined = getSortedData(sortBy, prestartChecks);
    setSortedData(prestartChecksSortedByDeptOrCategory ?? {});
  }, [prestartChecks, sortBy]);

  return (
    <div>
      <div
        className={`table_manage_prestart  ${
          sortBy === SortBy.DEPARTMENT ? 'table_dept' : 'table_cat'
        }  `}
      >
        <table cellPadding="0" cellSpacing="0">
          <thead className="fixed-header">
            <tr>
              <th>{messages.PRESTART.TABLE.ASSET_ID}</th>
              {sortBy !== 'department' && (
                <th>{messages.PRESTART.TABLE.DEPARTMENT}</th>
              )}
              <th>{messages.PRESTART.TABLE.STATUS}</th>
              <th>{messages.PRESTART.TABLE.REF_NO}</th>
              <th>{messages.PRESTART.TABLE.OPERATOR}</th>
              <th>{messages.PRESTART.TABLE.LOGIN_ENGINE_ON}</th>
              <th>{messages.PRESTART.TABLE.CHECK_TIME}</th>
              <th>{messages.PRESTART.TABLE.FAILED_CHECKS}</th>
              <th>{messages.PRESTART.TABLE.ENGINE_HOURS}</th>
              <th>{messages.PRESTART.TABLE.SIGNATURE}</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className={`table_manage_prestart  ${
          sortBy === 'department' ? 'table_dept' : 'table_cat'
        }   ${showCharts ? 'height-calc' : 'height-calculate'}  }  `}
      >
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            {Object.entries(sortedData)?.map(
              ([sectionTitle, sectionData]: [
                string,
                ExtendedPrestartCheck[] | SortedDataByCategory,
              ]) => {
                if (sortBy === 'department') {
                  return (
                    <React.Fragment key={sectionTitle}>
                      <LabelRow>
                        <strong>{sectionTitle}</strong>
                      </LabelRow>

                      {Object.entries(sectionData)?.map(
                        ([categoryName, sortedPrestartChecks]: [
                          string,
                          ExtendedPrestartCheck[],
                        ]) => (
                          <React.Fragment key={categoryName}>
                            <LabelRow className="grey-background">
                              <i>{categoryName}</i>
                            </LabelRow>
                            {isArray(sortedPrestartChecks) &&
                              sortedPrestartChecks.map(
                                (prestartCheck: PrestartCheckForTable) => (
                                  <PrestartRow
                                    onClick={onRowClick}
                                    sortBy={sortBy}
                                    prestartCheck={prestartCheck}
                                    key={prestartCheck._id}
                                    isSelected={isPrestartSelected(
                                      selectedPrestartId,
                                      prestartCheck,
                                    )}
                                  />
                                ),
                              )}
                          </React.Fragment>
                        ),
                      )}
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={sectionTitle}>
                      <LabelRow className="grey-background">
                        <i>{sectionTitle}</i>
                      </LabelRow>
                      {isArray(sectionData) &&
                        sectionData.map(
                          (prestartCheck: PrestartCheckForTable) => (
                            <PrestartRow
                              onClick={onRowClick}
                              sortBy={sortBy}
                              prestartCheck={prestartCheck}
                              key={prestartCheck._id}
                              isSelected={isPrestartSelected(
                                selectedPrestartId,
                                prestartCheck,
                              )}
                            />
                          ),
                        )}
                    </React.Fragment>
                  );
                }
              },
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
