import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styles from './Timeline.module.css';
import {
  ProductivityDataArray,
  ProductivityTimeline,
  StartEndIndex,
  TimelineData,
  TimelineGranularity,
  TimelineTimeUseAPIData,
  TimelineTypes,
  TimeUseTimeline,
  UtilisationDataArray,
  UtilisationTimeline,
} from './models';
import { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  aggregateProductivityData,
  aggregateTimeUseData,
  aggregateUtilisationData,
  calculateInactiveTime,
  calculateTransmissionTime,
  generateData,
  generateProductivityData,
  generateTimeUseData,
  generateUtilisationData,
  isDateInRange,
  isMonthInRange,
} from './helper';
import { SelectedAsset, TruckType } from '../model';
import CustomTooltip from './customTooltip';
import { maxHeightBreakPoint, MESSAGES } from '../constants';
import {
  getDmuTonnageForTimeline,
  getTimelineEngineHours,
  getTimelineIdleTime,
  getTimelinePushes,
  getTimelineSpeed,
  getTimelineTimeUse,
} from '../services';
import { useAssetContext } from '../../pages/service/assetContext';
import { DateRange } from 'react-day-picker';
import LoaderSpinner from '../assets/LoaderSpinner';
import ArrowLeft from '../assets/ArrowLeft';
import ArrowRight from '../assets/ArrowRight';
import useMediaQuery from '../../hooks/useMediaQuery';
import { AuthContext } from '../../pages/service/auth';
import { areSameDate, currentDateNZ } from '../helper';

type Props = {
  granularity: TimelineGranularity;
  type: TimelineTypes;
  finalRange: DateRange | undefined;
  setLoading: Function;
  loading: boolean;
  data: Object[];
  setData: Function;
  setxAxisValueSelect: Function;
  xAxisValueSelect: string | undefined;
  parentWidth: number;
};

interface CustomizedAxisTickDailyProps {
  x: number;
  y: number;
  payload: { value: string };
  finalRange: DateRange | undefined;
  granularity: TimelineGranularity;
  setxAxisValueSelect: Function;
  xAxisValueSelect: string | undefined;
}
const CustomizedAxisTickDaily: React.FC<CustomizedAxisTickDailyProps> = ({
  x,
  y,
  payload,
  finalRange,
  granularity,
  setxAxisValueSelect,
}) => {
  const flag =
    granularity === TimelineGranularity.Daily
      ? isDateInRange(
          moment(finalRange?.from).format('DD/MM/YYYY') ??
            moment(new Date()).format('DD/MM/YYYY'),
          finalRange?.to
            ? moment(finalRange?.to).format('DD/MM/YYYY')
            : undefined,
          typeof payload.value === typeof new Date()
            ? moment(payload.value).format('DD/MM/YYYY')
            : payload.value,
        )
      : isMonthInRange(
          moment(finalRange?.from).format('DD/MM/YYYY') ??
            moment(new Date()).format('DD/MM/YYYY'),
          moment(finalRange?.to).format('DD/MM/YYYY'),
          payload.value,
        );

  const { height } = useMediaQuery();

  return (
    <g
      transform={`translate(${x},${y})`}
      id={payload.value.toString()}
      style={{ cursor: 'pointer' }}
      onClick={(e) => {
        if (e.currentTarget.id) setxAxisValueSelect(e.currentTarget.id);
      }}
    >
      <rect
        x={-33}
        y={-2}
        width={66}
        height={17}
        rx="5"
        ry="5"
        fill={flag ? '#2B2D2F' : 'transparent'}
      />
      <text
        x={0}
        y={10}
        fontSize={height <= maxHeightBreakPoint ? 10 : 11}
        textAnchor="middle"
        fill={flag ? 'white' : '#808080'}
      >
        {granularity === TimelineGranularity.Daily
          ? typeof payload.value === 'object'
            ? moment(payload.value).format('DD/MM/YYYY')
            : payload.value
          : payload.value}
      </text>
    </g>
  );
};

const NewGraphs = ({
  granularity,
  type,
  finalRange,
  setLoading,
  loading,
  data,
  setData,
  xAxisValueSelect,
  setxAxisValueSelect,
  parentWidth,
}: Props) => {
  const { selectedAssets } = useAssetContext();
  const [graphData, setGraphData] = useState<
    ProductivityTimeline | TimeUseTimeline | UtilisationTimeline
  >([]);
  const [startEndIndex, setStartEndIndex] = useState<StartEndIndex>({
    start: 0,
    end: 0,
  });

  const abortControllers = useRef(new Map<string, AbortController>());

  const createAbortController = (key: string): AbortController => {
    const abortController = new AbortController();
    abortControllers.current.set(key, abortController);
    return abortController;
  };

  const abortPreviousRequests = (): void => {
    abortControllers.current.forEach((controller) => controller.abort());
    abortControllers.current.clear();
  };

  const { logOut } = useContext(AuthContext);

  const fetchData = async (showLoader = true): Promise<void> => {
    if (selectedAssets[0]?.type === TruckType.DMU) {
      setData(generateData(selectedAssets[0]?.type, type, granularity));
    } else {
      abortPreviousRequests(); // Abort any previous requests
      setData([]);
      setGraphData([]);
      let fetchedData:
        | ProductivityTimeline
        | TimeUseTimeline
        | UtilisationTimeline = [];

      if (type === TimelineTypes.Productivity) {
        const fetchProductivity = async (): Promise<ProductivityTimeline> => {
          let productivityData: ProductivityTimeline = [];
          try {
            if (showLoader) {
              setLoading(true);
            }
            const productivityDataArray: ProductivityDataArray[] =
              await Promise.all(
                selectedAssets.map(async (asset: SelectedAsset) => {
                  const [pushesResult, speedResult] = await Promise.allSettled([
                    getTimelinePushes(
                      asset.name,
                      granularity,
                      finalRange,
                      createAbortController('getTimelinePushes').signal,
                      logOut,
                    ),
                    getTimelineSpeed(
                      asset.name,
                      granularity,
                      finalRange,
                      createAbortController('getTimelineSpeed').signal,
                      logOut,
                    ),
                  ]);

                  const pushes =
                    pushesResult.status === 'fulfilled'
                      ? pushesResult.value
                      : [];
                  const speed =
                    speedResult.status === 'fulfilled' ? speedResult.value : [];
                  return { pushes, speed };
                }),
              );
            const aggregatedProductivityData: ProductivityDataArray =
              aggregateProductivityData(
                productivityDataArray,
                selectedAssets.length,
              );
            productivityData = generateProductivityData(
              granularity,
              aggregatedProductivityData.pushes,
              aggregatedProductivityData.speed,
            );
          } catch (error) {
          } finally {
            setLoading(false);
          }
          return productivityData;
        };
        fetchedData = await fetchProductivity();
      } else if (type === TimelineTypes.TimeUse) {
        const fetchTimeUse = async (): Promise<TimeUseTimeline> => {
          let timeUseData: TimeUseTimeline = [];
          try {
            if (showLoader) {
              setLoading(true);
            }
            const timeUseDataArray: {
              timeUse: TimelineTimeUseAPIData[];
            }[] = await Promise.all(
              selectedAssets.map(async (asset: SelectedAsset) => {
                const timeUse = await getTimelineTimeUse(
                  asset.name,
                  granularity,
                  finalRange,
                  createAbortController('getTimelineTimeUse').signal,
                  logOut,
                );
                return { timeUse };
              }),
            );
            const aggregatedTimeUseData: TimelineTimeUseAPIData[] =
              aggregateTimeUseData(timeUseDataArray);
            timeUseData = generateTimeUseData(
              aggregatedTimeUseData,
              granularity,
            );
          } catch (error) {
          } finally {
            setLoading(false);
          }
          return timeUseData;
        };
        fetchedData = await fetchTimeUse();
      } else if (type === TimelineTypes.Utilisation) {
        const fetchUtilisation = async (): Promise<UtilisationTimeline> => {
          let utilisationData: UtilisationTimeline = [];
          try {
            if (showLoader) {
              setLoading(true);
            }
            const utilisationDataArray: (
              | UtilisationDataArray
              | TimelineData[]
            )[] = await Promise.all([
              ...selectedAssets.map(async (asset: SelectedAsset) => {
                const [engineHoursResult, idleTimeResult] =
                  await Promise.allSettled([
                    getTimelineEngineHours(
                      asset.name,
                      granularity,
                      finalRange,
                      createAbortController('getTimelineEngine').signal,
                      logOut,
                    ),
                    getTimelineIdleTime(
                      asset.name,
                      granularity,
                      finalRange,
                      createAbortController('getTimelineIdle').signal,
                      logOut,
                    ),
                  ]);
                const engineHours: TimelineData[] =
                  engineHoursResult.status === 'fulfilled'
                    ? engineHoursResult.value
                    : [];
                const idleTime: TimelineData[] =
                  idleTimeResult.status === 'fulfilled'
                    ? idleTimeResult.value
                    : [];

                const transmissionTime: TimelineData[] =
                  calculateTransmissionTime(engineHours, idleTime);

                const inactiveTime: TimelineData[] = calculateInactiveTime(
                  engineHours,
                  granularity,
                );

                return { transmissionTime, idleTime, inactiveTime };
              }),
              getDmuTonnageForTimeline(
                granularity,
                finalRange,
                createAbortController('getDmuTonnageForTimeline').signal,
                logOut,
              ),
            ]);

            const utilisationDataForAllTrucks = utilisationDataArray.slice(
              0,
              -1,
            ) as UtilisationDataArray[];

            // Combine idle/engine TimelineData for multiple trucks into one TimelineData array each
            const aggregatedUtilisationData: UtilisationDataArray =
              aggregateUtilisationData(utilisationDataForAllTrucks);

            utilisationData = generateUtilisationData(
              granularity,
              aggregatedUtilisationData.transmissionTime,
              aggregatedUtilisationData.idleTime,
              aggregatedUtilisationData.inactiveTime,
              utilisationDataArray.at(-1) as TimelineData[], //tonnage data
            );
          } catch (error) {
          } finally {
            setLoading(false);
          }
          return utilisationData;
        };
        fetchedData = await fetchUtilisation();
      } else {
        setData(generateData(selectedAssets[0]?.type, type, granularity));
      }
      if (showLoader && fetchedData.length > 0) {
        const max: number = Math.floor(parentWidth / 100) - 2;
        if (max >= fetchedData.length) {
          setStartEndIndex({ start: 0, end: fetchedData.length - 1 });
        } else {
          let start: number = 0;
          let end: number = max - 1;
          const rangeDate: Date | undefined =
            finalRange?.to ?? finalRange?.from;
          if (rangeDate) {
            if (granularity === TimelineGranularity.Daily) {
              const index: number = fetchedData.findIndex((dataPoint) => {
                if ('date' in dataPoint) {
                  return (
                    dataPoint.date === moment(rangeDate).format('DD/MM/YYYY')
                  );
                }
                return false;
              });
              if (index !== -1 && index > end) {
                end = index;
                start = index - max + 1 < 0 ? 0 : index - max + 1;
              }
            } else if (
              granularity === TimelineGranularity.Hourly &&
              areSameDate(currentDateNZ, rangeDate)
            ) {
              let hours: string = currentDateNZ
                .getHours()
                .toString()
                .padStart(2, '0');
              hours = `${hours}:00`;
              const index: number = fetchedData.findIndex((dataPoint) => {
                if ('hour' in dataPoint) {
                  return dataPoint.hour === hours;
                }
                return false;
              });
              if (index !== -1 && index > end) {
                end = index;
                start = index - max + 1 < 0 ? 0 : index - max + 1;
              }
            }
          }
          setStartEndIndex({ start, end });
        }
      }
      setData(fetchedData);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      abortPreviousRequests(); // Abort requests on component unmount
    };
  }, [granularity, type, finalRange, selectedAssets]);

  // useEffect(() => {
  //   const interval = setInterval(
  //     () => {
  //       fetchData(false);
  //     },
  //     Number(process.env.REACT_APP_API_REFRESH_INTERVAL_IN_MINUTES ?? 5) *
  //       60 *
  //       1000,
  //   );

  //   return () => {
  //     clearInterval(interval);
  //     abortPreviousRequests(); // Abort requests on component unmount
  //   };
  // }, [granularity, type, selectedAsset, finalRange]);

  useEffect(() => {
    console.log({ data });

    let tempGraphData:
      | ProductivityTimeline
      | TimeUseTimeline
      | UtilisationTimeline = JSON.parse(JSON.stringify(data));

    setGraphData(
      tempGraphData.slice(startEndIndex.start, startEndIndex.end + 1),
    );
  }, [startEndIndex, data]);

  const handleLeft = (): void => {
    if (startEndIndex.start > 0) {
      if (startEndIndex.start - 3 < 0) {
        setStartEndIndex({
          start: 0,
          end: startEndIndex.end - startEndIndex.start,
        });
      } else {
        setStartEndIndex({
          start: startEndIndex.start - 3,
          end: startEndIndex.end - 3,
        });
      }
    }
  };

  const handleRight = (): void => {
    if (startEndIndex.end < data.length - 1) {
      if (startEndIndex.end + 3 < data.length - 1) {
        setStartEndIndex({
          start: startEndIndex.start + 3,
          end: startEndIndex.end + 3,
        });
      } else {
        setStartEndIndex({
          start: data.length - 1 - (startEndIndex.end - startEndIndex.start),
          end: data.length - 1,
        });
      }
    }
  };

  const { height } = useMediaQuery();

  return (
    <>
      <div className={styles.mainContainer}>
        {loading ? (
          <LoaderSpinner
            height={50}
            width={50}
            containerHeight={height <= maxHeightBreakPoint ? 245 : 276}
          />
        ) : (
          <div className={styles.graphContainer}>
            <div className={styles.leftRightToggleContainer}>
              <div
                className={styles.leftRightToggle}
                onClick={handleLeft}
                style={{
                  cursor: startEndIndex.start === 0 ? 'not-allowed' : '',
                }}
              >
                <ArrowLeft
                  height={height <= maxHeightBreakPoint ? 18 : 21}
                  width={height <= maxHeightBreakPoint ? 18 : 21}
                />
              </div>
              <div
                className={styles.leftRightToggle}
                onClick={handleRight}
                style={{
                  cursor:
                    startEndIndex.end === data.length - 1 ? 'not-allowed' : '',
                }}
              >
                <ArrowRight
                  height={height <= maxHeightBreakPoint ? 18 : 21}
                  width={height <= maxHeightBreakPoint ? 18 : 21}
                />
              </div>
            </div>
            <ComposedChart
              width={
                type === TimelineTypes.Productivity
                  ? parentWidth - 40
                  : parentWidth - 100
              }
              height={height <= maxHeightBreakPoint ? 200 : 220}
              data={graphData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid
                strokeDasharray="6 4"
                vertical={false}
                stroke="#E8E8E8"
              />
              <Tooltip
                content={
                  <CustomTooltip
                    granuality={granularity}
                    timelineType={type}
                    assetType={selectedAssets[0]?.type}
                  />
                }
              />
              <YAxis
                yAxisId="left"
                tickLine={false}
                axisLine={{ stroke: 'transparent' }}
                label={{
                  value:
                    selectedAssets[0]?.type === TruckType.DMU
                      ? MESSAGES.tonnes
                      : type === TimelineTypes.Productivity
                      ? MESSAGES.pushes
                      : type === TimelineTypes.TimeUse ||
                        type === TimelineTypes.Utilisation
                      ? granularity === TimelineGranularity.Hourly
                        ? MESSAGES.mins
                        : MESSAGES.hrs
                      : MESSAGES.ltrs,
                  angle: -90,
                  position: 'insideLeft',
                  fontSize: height <= maxHeightBreakPoint ? '10px' : '12px',
                  dx: 15,
                  dy: 10,
                }}
                tick={{
                  fontSize: height <= maxHeightBreakPoint ? '10px' : '12px',
                  color: '#6F6F6F',
                }}
                tickFormatter={(tick) => {
                  if (tick < 0) {
                    return 0;
                  }
                  if (
                    type === TimelineTypes.Utilisation &&
                    granularity !== TimelineGranularity.Hourly
                  ) {
                    return Math.round(tick / 60);
                  }
                  return tick;
                }}
              />
              {(type === TimelineTypes.Productivity ||
                type === TimelineTypes.Utilisation) && (
                <YAxis
                  yAxisId="right"
                  tickLine={false}
                  axisLine={{ stroke: 'transparent' }}
                  orientation="right"
                  tickFormatter={(tick) => {
                    if (tick < 0) {
                      return 0;
                    }
                    return tick;
                  }}
                  tick={{
                    fontSize: height <= maxHeightBreakPoint ? '10px' : '12px',
                    color: '#6F6F6F',
                  }}
                  label={{
                    value:
                      type === TimelineTypes.Productivity
                        ? 'Speed (km/h)'
                        : MESSAGES.tonnes,
                    angle: -90,
                    position: 'insideRight',
                    fontSize: height <= maxHeightBreakPoint ? '10px' : '12px',
                    dx: type === TimelineTypes.Utilisation ? 0 : -10,
                    dy: -35,
                  }}
                />
              )}
              {granularity === TimelineGranularity.Hourly && (
                <>
                  <Line
                    type="linear"
                    dataKey={
                      selectedAssets[0]?.type === TruckType.DMU
                        ? 'fedIntoMill'
                        : type === TimelineTypes.Productivity
                        ? 'pushes'
                        : type === TimelineTypes.TimeUse
                        ? 'contactTime'
                        : type === TimelineTypes.Utilisation
                        ? 'transmissionTime'
                        : 'fuel'
                    }
                    stroke={
                      selectedAssets[0]?.type === TruckType.DMU
                        ? '#1C4E80'
                        : type === TimelineTypes.Productivity
                        ? '#7638D9'
                        : type === TimelineTypes.TimeUse
                        ? '#3BC6B3'
                        : type === TimelineTypes.Utilisation
                        ? '#1A4C7E'
                        : '#D938B4'
                    }
                    yAxisId="left"
                    dot={false}
                  />
                  {type !== TimelineTypes.Fuel && (
                    <Line
                      type="linear"
                      dataKey={
                        selectedAssets[0]?.type === TruckType.DMU
                          ? 'overburden'
                          : type === TimelineTypes.Productivity
                          ? 'speed'
                          : type === TimelineTypes.TimeUse
                          ? 'nearbyTime'
                          : 'idleTime'
                      }
                      stroke={
                        selectedAssets[0]?.type === TruckType.DMU
                          ? '#E67F5A'
                          : type === TimelineTypes.Productivity
                          ? '#7E8593'
                          : type === TimelineTypes.TimeUse
                          ? '#F9AA04'
                          : '#69D8E3'
                      }
                      yAxisId={
                        type === TimelineTypes.Productivity &&
                        selectedAssets[0]?.type !== TruckType.DMU
                          ? 'right'
                          : 'left'
                      }
                      dot={false}
                    />
                  )}
                  {type === TimelineTypes.Utilisation && (
                    <>
                      <Line
                        type="linear"
                        dataKey={'inactiveTime'}
                        stroke={'#E3E3E3'}
                        yAxisId={'left'}
                        dot={false}
                      />
                      <Line
                        type="linear"
                        dataKey={'tonnage'}
                        stroke={'#7638D9'}
                        yAxisId={'right'}
                        dot={false}
                      />
                    </>
                  )}
                  <XAxis
                    dataKey="hour"
                    tickFormatter={(tickDate) => {
                      return tickDate;
                    }}
                    allowDataOverflow={true}
                    axisLine={{ stroke: 'transparent' }}
                    tickLine={false}
                    tick={{
                      fontSize: height <= maxHeightBreakPoint ? '10px' : '12px',
                      fill: '#808080',
                    }}
                    tickMargin={15}
                  />
                </>
              )}
              {granularity === TimelineGranularity.Daily && (
                <>
                  <XAxis
                    dataKey="date"
                    allowDataOverflow={true}
                    axisLine={{ stroke: 'transparent' }}
                    tickFormatter={(tickDate) => {
                      if (selectedAssets[0]?.type === TruckType.BULLDOZER) {
                        return tickDate;
                      } else {
                        return moment(tickDate).format('DD/MM/YYYY');
                      }
                    }}
                    tickLine={false}
                    tick={(props: CustomizedAxisTickDailyProps) => (
                      <CustomizedAxisTickDaily
                        {...props}
                        finalRange={finalRange}
                        granularity={granularity}
                        xAxisValueSelect={xAxisValueSelect}
                        setxAxisValueSelect={setxAxisValueSelect}
                      />
                    )}
                    tickMargin={15}
                  />
                  {/* {type === TimelineTypes.Productivity && (
                    <ReferenceLine
                      y={selectedAssets[0]?.type === TruckType.DMU ? '200' : 8}
                      yAxisId="left"
                      stroke="#3AA75F"
                      strokeDasharray="5 4"
                      strokeWidth="1"
                    />
                  )} */}
                  <Bar
                    dataKey={
                      selectedAssets[0]?.type === TruckType.DMU
                        ? 'fedIntoMill'
                        : type === TimelineTypes.Productivity
                        ? 'pushes'
                        : type === TimelineTypes.TimeUse
                        ? 'contactTime'
                        : type === TimelineTypes.Utilisation
                        ? 'transmissionTime'
                        : 'fuel'
                    }
                    fill={
                      selectedAssets[0]?.type === TruckType.DMU
                        ? '#1C4E80'
                        : type === TimelineTypes.Productivity
                        ? '#7638D9'
                        : type === TimelineTypes.TimeUse
                        ? '#3BC6B3'
                        : type === TimelineTypes.Utilisation
                        ? '#1A4C7E'
                        : '#D938B4'
                    }
                    yAxisId="left"
                    barSize={12}
                  />
                  {type !== TimelineTypes.Fuel && (
                    <Bar
                      dataKey={
                        selectedAssets[0]?.type === TruckType.DMU
                          ? 'overburden'
                          : type === TimelineTypes.Productivity
                          ? 'speed'
                          : type === TimelineTypes.TimeUse
                          ? 'nearbyTime'
                          : 'idleTime'
                      }
                      fill={
                        selectedAssets[0]?.type === TruckType.DMU
                          ? '#E67F5A'
                          : type === TimelineTypes.Productivity
                          ? '#7E8593'
                          : type === TimelineTypes.TimeUse
                          ? '#F9AA04'
                          : '#69D8E3'
                      }
                      yAxisId={
                        type === TimelineTypes.Productivity &&
                        selectedAssets[0]?.type !== TruckType.DMU
                          ? 'right'
                          : 'left'
                      }
                      barSize={12}
                    />
                  )}
                  {type === TimelineTypes.Utilisation && (
                    <>
                      <Bar
                        dataKey={'inactiveTime'}
                        fill={'#E3E3E3'}
                        yAxisId={'left'}
                        barSize={12}
                      />
                      <Bar
                        dataKey={'tonnage'}
                        fill={'#7638D9'}
                        yAxisId={'right'}
                        barSize={12}
                      />
                    </>
                  )}
                </>
              )}
              {granularity === TimelineGranularity.Monthly && (
                <>
                  <XAxis
                    dataKey="month"
                    allowDataOverflow={true}
                    axisLine={{ stroke: 'transparent' }}
                    tickLine={false}
                    tick={(props: CustomizedAxisTickDailyProps) => (
                      <CustomizedAxisTickDaily
                        {...props}
                        finalRange={finalRange}
                        granularity={granularity}
                        xAxisValueSelect={xAxisValueSelect}
                        setxAxisValueSelect={setxAxisValueSelect}
                      />
                    )}
                    tickMargin={15}
                  />
                  {type === TimelineTypes.Productivity && (
                    <ReferenceLine
                      y={8}
                      yAxisId="left"
                      stroke="#3AA75F"
                      strokeDasharray="5 4"
                      strokeWidth="1"
                    />
                  )}
                  <Bar
                    dataKey={
                      selectedAssets[0]?.type === TruckType.DMU
                        ? 'fedIntoMill'
                        : type === TimelineTypes.Productivity
                        ? 'pushes'
                        : type === TimelineTypes.TimeUse
                        ? 'contactTime'
                        : type === TimelineTypes.Utilisation
                        ? 'transmissionTime'
                        : 'fuel'
                    }
                    fill={
                      selectedAssets[0]?.type === TruckType.DMU
                        ? '#1C4E80'
                        : type === TimelineTypes.Productivity
                        ? '#7638D9'
                        : type === TimelineTypes.TimeUse
                        ? '#3BC6B3'
                        : type === TimelineTypes.Utilisation
                        ? '#1A4C7E'
                        : '#D938B4'
                    }
                    yAxisId="left"
                    barSize={12}
                  />
                  {type !== TimelineTypes.Fuel && (
                    <Bar
                      dataKey={
                        selectedAssets[0]?.type === TruckType.DMU
                          ? 'overburden'
                          : type === TimelineTypes.Productivity
                          ? 'speed'
                          : type === TimelineTypes.TimeUse
                          ? 'nearbyTime'
                          : 'idleTime'
                      }
                      fill={
                        selectedAssets[0]?.type === TruckType.DMU
                          ? '#E67F5A'
                          : type === TimelineTypes.Productivity
                          ? '#7E8593'
                          : type === TimelineTypes.TimeUse
                          ? '#F9AA04'
                          : '#69D8E3'
                      }
                      yAxisId={
                        type === TimelineTypes.Productivity &&
                        selectedAssets[0]?.type !== TruckType.DMU
                          ? 'right'
                          : 'left'
                      }
                      barSize={12}
                    />
                  )}
                  {type === TimelineTypes.Utilisation && (
                    <>
                      <Bar
                        dataKey={'inactiveTime'}
                        fill={'#E3E3E3'}
                        yAxisId={'left'}
                        barSize={12}
                      />
                      <Bar
                        dataKey={'tonnage'}
                        fill={'#7638D9'}
                        yAxisId={'right'}
                        barSize={12}
                      />
                    </>
                  )}
                </>
              )}
            </ComposedChart>
          </div>
        )}
      </div>
    </>
  );
};

export default NewGraphs;
