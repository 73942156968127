import { createContext, useEffect, useState } from 'react';

import { useIntervalFetch } from '../hooks/useIntervalFetch';
import { GET_SHIFT_HISTORY } from '../action/apiPath';
import { CallWithAuth } from '../action/apiActions';

const initialState = {
  shift: [],
  ongoingShift: [],
  previousShift: [],
};
export const ShiftHistoryContext = createContext(initialState);

export const FetchShiftHistoryData = () => {
  const [state, dispatch] = useIntervalFetch('History', fetch, initialState);

  // 0 = Ongoing Shift
  // 1 = Previous Shift
  const [shiftChoice, setShiftChoice] = useState('0');
  useEffect(() => {
    switch (shiftChoice) {
      case '0':
        dispatch({ type: 'shift', payload: state.ongoingShift });
        break;

      case '1':
        dispatch({ type: 'shift', payload: state.previousShift });
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ongoingShift, state.previousShift, shiftChoice]);

  return {
    shiftChoice,
    setShiftChoice,

    ...state,
  };
};

const fetch = (dispatch) => {
  const fetchShiftHistory = async () => {
    try {
      const response = await CallWithAuth('GET', GET_SHIFT_HISTORY);
      if (response.status) {
        dispatch({
          type: 'ongoingShift',
          payload: response.res.data[0],
        });
        dispatch({
          type: 'previousShift',
          payload: response.res.data[1],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchShiftHistory();
};
