import { FunctionComponent } from 'react';
import { IconProps } from '../../helper/types';

type ReportCategoryIconProps = {
  color: string;
  text: string;
};

const ReportCategoryIcon: FunctionComponent<
  ReportCategoryIconProps & IconProps
> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '14.5'}
      height={props.size ?? '12.665'}
      viewBox="0 0 14.5 12.665"
    >
      <g
        id="Group_52053"
        data-name="Group 52053"
        transform="translate(0.018 0.5)"
      >
        <g
          id="Icon_feather-alert-triangle"
          data-name="Icon feather-alert-triangle"
        >
          <path
            id="Path_21024"
            data-name="Path 21024"
            d="M10.162,4.966l5.458,9.111a1.289,1.289,0,0,1-1.1,1.933H3.6a1.289,1.289,0,0,1-1.1-1.933L7.959,4.966a1.289,1.289,0,0,1,2.2,0Z"
            transform="translate(-1.828 -4.346)"
            fill="#fff"
            stroke={props.color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <text
            id="C"
            transform="translate(4.732 8.946)"
            fill={props.color}
            fontSize="7"
            font-family="OpenSans-Bold, Open Sans"
            font-weight="700"
            letterSpacing="0.04em"
          >
            <tspan x="0" y="0">
              {props.text}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export default ReportCategoryIcon;
