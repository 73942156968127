import Spinner from 'react-bootstrap/Spinner';

const Spinners = () => {
  return (
    <div
      style={{
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        top: 0,
        right: 0,
        zIndex: 100,
        background: 'white',
      }}
    >
      <div
        style={{
          width: '3%',
          height: '100%',
          margin: 'auto',
          padding: '25%',
        }}
      >
        <Spinner animation="border" />
      </div>
    </div>
  );
};

export default Spinners;
