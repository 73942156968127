import { FunctionComponent } from 'react';
import ProductivitySummary from './productivitySummary';
import UtilizationSummary from './utilizationSummary';

import {
  BulldozerSummary,
  SummarySectionCategory,
  TruckCategory,
} from '../../../models';
import { DateRange } from 'react-day-picker';

type SectionProps = {
  sectionCategory: SummarySectionCategory;
  truckCategory: TruckCategory;
  range: DateRange | undefined;
  data: BulldozerSummary;
};

const SummarySection: FunctionComponent<SectionProps> = (
  props: SectionProps,
) => {
  if (props.truckCategory !== TruckCategory.DMU) {
    return props.sectionCategory === SummarySectionCategory.PRODUCTIVITY ? (
      <ProductivitySummary
        truckCategory={props.truckCategory}
        range={props.range}
        data={props.data}
      />
    ) : (
      <UtilizationSummary
        truckCategory={props.truckCategory}
        range={props.range}
        data={props.data}
      />
    );
  }
  return (
    <ProductivitySummary
      data={props.data}
      truckCategory={props.truckCategory}
      range={props.range}
    />
  );
};

export default SummarySection;
