import React from 'react';

interface PerformanceProps {
  height?: number;
  width?: number;
}

const Performance: React.FC<PerformanceProps> = ({
  height = 12.52,
  width = 16.475,
}) => {
  return (
    <svg
      viewBox="0 0 16.475 12.52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#808080"
      height={height}
      width={width}
    >
      <g id="PERFORMANCE" transform="translate(-6.038 -9.31)">
        <line
          id="Line_2277"
          data-name="Line 2277"
          x1="6"
          y2="10"
          transform="translate(15.275 10.813)"
          fill="none"
          stroke="#319844"
          stroke-linecap="round"
          stroke-width="1"
        />
        <line
          id="Line_2308"
          data-name="Line 2308"
          x1="3.386"
          y2="4.965"
          transform="translate(7.275 15.848)"
          fill="none"
          stroke="#319844"
          stroke-linecap="round"
          stroke-width="1"
        />
        <line
          id="Line_2307"
          data-name="Line 2307"
          x2="4.035"
          y2="5.375"
          transform="translate(11.24 15.438)"
          fill="none"
          stroke="#319844"
          stroke-linecap="round"
          stroke-width="1"
        />
        <g
          id="Ellipse_3238"
          data-name="Ellipse 3238"
          transform="matrix(0.985, -0.174, 0.174, 0.985, 6.038, 19.726)"
          fill="#fff"
          stroke="#319844"
          stroke-width="0.5"
        >
          <circle cx="1.068" cy="1.068" r="1.068" stroke="none" />
          <circle cx="1.068" cy="1.068" r="0.818" fill="none" />
        </g>
        <g
          id="Ellipse_3513"
          data-name="Ellipse 3513"
          transform="matrix(0.985, -0.174, 0.174, 0.985, 14.039, 19.726)"
          fill="#fff"
          stroke="#319844"
          stroke-width="0.5"
        >
          <circle cx="1.068" cy="1.068" r="1.068" stroke="none" />
          <circle cx="1.068" cy="1.068" r="0.818" fill="none" />
        </g>
        <g
          id="Ellipse_3515"
          data-name="Ellipse 3515"
          transform="matrix(0.985, -0.174, 0.174, 0.985, 20.038, 9.681)"
          fill="#fff"
          stroke="#319844"
          stroke-width="0.5"
        >
          <circle cx="1.068" cy="1.068" r="1.068" stroke="none" />
          <circle cx="1.068" cy="1.068" r="0.818" fill="none" />
        </g>
        <g
          id="Ellipse_3514"
          data-name="Ellipse 3514"
          transform="matrix(0.985, -0.174, 0.174, 0.985, 9.734, 14.204)"
          fill="#fff"
          stroke="#319844"
          stroke-width="0.5"
        >
          <circle cx="1.068" cy="1.068" r="1.068" stroke="none" />
          <circle cx="1.068" cy="1.068" r="0.818" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default Performance;
