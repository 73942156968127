export type Asset = {
  type: TruckType;
  status: boolean;
  currentSpeed?: number;
  totalPushes?: number;
  _id: number;
  name: string;
  camel_id: string;
  tripTimeStamp?: number;
  totalTonnesProcessed?: number;
  idleTime?: number;
  calculatedEngineMinutes: number;
};

export enum EngineStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum TruckType {
  DMU = 0,
  BULLDOZER = 1 | 5,
}

export const DMU: Asset = {
  calculatedEngineMinutes: new Date().setHours(0, 0),
  type: TruckType.DMU,
  status: false,
  idleTime: new Date().setHours(0, 0),
  totalTonnesProcessed: 0,
  _id: 1,
  name: 'DMU',
  camel_id: 'DMU0',
};

export enum TrafficLightColor {
  RED = 'red',
  GREEN = 'green',
}

export type CurrentTrafficLightStatus = {
  status: TrafficLightColor | null;
};

export type CurrentTonnage = {
  tonnage: number;
};

export type SelectedAsset = { name: string; type: TruckType };

export type RequestConfig = {
  headers: {
    Authorization: string;
  };
  signal: AbortSignal;
};

export type EngineHours = {
  deviceId: string;
  truckName: string;
  calculatedEngineHours: number;
  calculatedEngineMinutes: number;
  totalEngineHoursInMinutes: number;
  currentEngineStatus: string;
  lastUpdatedTime: string;
};

export type ContactNearbySummary = {
  contact: number;
  nearby: number;
};
