import React, { FunctionComponent } from 'react';
import { CheckpointDetail } from '../../../../pages/utils/types';
import { countDefectsInCategory } from '../../../../pages/utils/helper';
import RenderSeparator from './renderSeparator';
import { getSecondWord } from './getSecondWord';

type DisplayFaultyChecksProps = { checkpoints: CheckpointDetail[] };

const DisplayFaultyChecks: FunctionComponent<DisplayFaultyChecksProps> = (
  props,
) => {
  return (
    <>
      {props.checkpoints?.map(({ checkpoints, category }, index) => (
        <React.Fragment key={category}>
          <strong>{getSecondWord(category)}</strong>{' '}
          <strong>({countDefectsInCategory(checkpoints)})</strong>
          <RenderSeparator
            arrayLength={props.checkpoints.length}
            index={index}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default DisplayFaultyChecks;
