import { BulldozerSummary } from '../../../models';

export const getBulldozerPushProgress = (
  currentPushes: number,
  maximumPushes: number,
): number => (currentPushes / maximumPushes) * 100;

export const getContactTimePercentage = (
  bulldozerProductivity: BulldozerSummary,
): number => {
  return (
    (bulldozerProductivity.contactTime /
      (bulldozerProductivity.contactTime + bulldozerProductivity.nearbyTime)) *
    100
  );
};

export const getTransmissionPercentage = (
  bullDozerUtilization: BulldozerSummary,
): number =>
  (bullDozerUtilization.transmissionTime /
    (bullDozerUtilization.idleTime +
      bullDozerUtilization.inactiveTime +
      bullDozerUtilization.transmissionTime)) *
  100;

export const getIdleTimePercentage = (
  bullDozerUtilization: BulldozerSummary,
): number =>
  (bullDozerUtilization.idleTime /
    (bullDozerUtilization.idleTime +
      bullDozerUtilization.inactiveTime +
      bullDozerUtilization.transmissionTime)) *
  100;

export const getInactiveTimePercentage = (
  bullDozerUtilization: BulldozerSummary,
): number =>
  (bullDozerUtilization.inactiveTime /
    (bullDozerUtilization.idleTime +
      bullDozerUtilization.inactiveTime +
      bullDozerUtilization.transmissionTime)) *
  100;
