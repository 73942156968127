import React from 'react';

interface EngineProps {
  height?: number;
  width?: number;
}

const Engine: React.FC<EngineProps> = ({ height = 16.174, width = 20.052 }) => {
  return (
    <svg
      viewBox="0 0 20.052 16.174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#808080"
      height={height}
      width={width}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_11686"
            data-name="Rectangle 11686"
            width="20.052"
            height="16.174"
            fill="none"
            stroke="#5b5b5b"
            stroke-width="1"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_11687"
            data-name="Rectangle 11687"
            width="17.377"
            height="12.832"
            fill="none"
            stroke="#5b5b5b"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g id="Group_52190" data-name="Group 52190">
        <g
          id="Group_52189-2"
          data-name="Group 52189"
          clip-path="url(#clip-path)"
        >
          <path
            id="Path_21220"
            data-name="Path 21220"
            d="M3.958,9.171h-1.6V6.765H.75v6.416h1.6V10.775h1.6m8.421-6.817v-1.6h2.005V.75H8.77v1.6h2.005v1.6"
            transform="translate(-0.349 -0.349)"
            fill="none"
            stroke="#5b5b5b"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </g>
      </g>
      <g
        id="Group_52192"
        data-name="Group 52192"
        transform="translate(2.673 3.342)"
      >
        <g
          id="Group_52191"
          data-name="Group 52191"
          clip-path="url(#clip-path-2)"
        >
          <path
            id="Path_21221"
            data-name="Path 21221"
            d="M21.187,9.505V11.51h-1.2V8.239a.734.734,0,0,0-.725-.739h-8.1a.718.718,0,0,0-.514.217L8.755,9.906h-1.2a.862.862,0,0,0-.8.8V15.52a.857.857,0,0,0,.8.8h1.2l1.89,2.191a.72.72,0,0,0,.512.215h5.155a.72.72,0,0,0,.512-.215l2.945-3a.743.743,0,0,0,.213-.522l0-1.074h1.2v2.005h1.6V9.505Z"
            transform="translate(-5.814 -6.832)"
            fill="none"
            stroke="#5b5b5b"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path
            id="Path_21222"
            data-name="Path 21222"
            d="M20.51,14.458,17.3,18.869l1.2-3.609H16.5l2.005-4.01H20.51l-2.005,3.208Z"
            transform="translate(-10.351 -8.577)"
            fill="none"
            stroke="#5b5b5b"
            stroke-width="0.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default Engine;
