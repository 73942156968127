import BasicButton from '../BasicButton';
import ModalView from '../ModalView';

const SmallAlertModal = ({ onClose, children, onClick, btnTxt }) => (
  <ModalView onClose={onClose}>
    <div className="alert-text">
      {children}
      <BasicButton
        onClick={() => {
          onClick();
          onClose();
        }}
      >
        {btnTxt}
      </BasicButton>
    </div>
  </ModalView>
);

export default SmallAlertModal;
