import { useContext } from 'react';
import { UserContext } from '../../service/userContext';
import { formatDateToDDMMYYYY } from '../../utils/helper';
import BasicButton from '../../components/BasicButton';
import CloseIcon from '../../assets/cross.svg';
import { UserType, sections, userType } from '../../utils/constants';
import { FormModal } from '../../components/FormModal';
const ViewAccount = ({ setModalShow, setVisible }) => {
  const { userInfo, usersRestrictedDepartments } = useContext(UserContext);
  const userDepartments = usersRestrictedDepartments
    .map(({ code }) => sections[code])
    .join(', ');
  const userData = [
    { label: 'Name:', value: `${userInfo?.first_name} ${userInfo?.last_name}` },
    { label: 'Email:', value: userInfo?.email },
    { label: 'User Type:', value: userType[userInfo?.type] ?? '-' },
    {
      label: 'Added By:',
      value: `${userInfo?.added_by?.first_name ?? '-'} ${
        userInfo?.added_by?.last_name ?? '-'
      }, ${formatDateToDDMMYYYY(userInfo?.added_by?.addedAt ?? new Date())}`,
    },
  ];
  if (userInfo.type === UserType.supervisor.value)
    userData.splice(3, 0, {
      label: 'Department:',
      value: userDepartments || '-',
    });
  return (
    <FormModal>
      <div className="position-absolute top-0 end-0 text-black pt-1">
        <img
          src={CloseIcon}
          className="me-2"
          alt="close"
          onClick={() => setVisible(false)}
        />
      </div>
      {userData.map(({ label, value }) => (
        <div key={label} className="d-flex justify-content-start py-1 w-100">
          <p style={{ flex: '0.3' }}>{label}</p>
          <p style={{ flex: '0.6', fontWeight: 'bold' }}>{value ?? ''}</p>
        </div>
      ))}

      <div className="d-flex gap-2 justify-content-start w-100 mt-3 text-center">
        <BasicButton
          outlined
          variant="outline-dark"
          onClick={() => setModalShow({ editProfile: true })}
        >
          Edit
        </BasicButton>
        <BasicButton
          onClick={() => setModalShow({ editPassword: true })}
          outlined
        >
          <p>Change Password</p>
        </BasicButton>
        {userInfo.type === userType.Supervisor ? (
          <BasicButton onClick={() => setModalShow({ viewPin: true })} outlined>
            View Pin
          </BasicButton>
        ) : null}
      </div>
    </FormModal>
  );
};

export default ViewAccount;
