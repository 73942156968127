// NOTE: This code is intentionally verbose for pedagogical purposes.

import { useReducer } from 'react';

const defaultReducer = (state, action) => {
  return state.hasOwnProperty(action.type)
    ? { ...state, [action.type]: action.payload }
    : state;
};

// Only dipatches events when the payload has changed.
// This saves re-renders, especially when data is being fetched at regular intervals.
export const useSmartReducer = (initialState, reducer = defaultReducer) => {
  const [state, originalDispatch] = useReducer(reducer, initialState);

  const dispatch = (action) => {
    const newPayload = action.payload;
    const oldPayload = state[action.type];

    if (JSON.stringify(newPayload) !== JSON.stringify(oldPayload)) {
      originalDispatch(action);
    }
  };

  return [state, dispatch];
};
