import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import pdf from '../../assets/icons/pdf.svg';
import removeLogo from '../../assets/icons/remove.svg';
import Uploadimage from '../../assets/icons/upload.svg';
import BasicButton from '../BasicButton';

function ImageUploader({ selectedImage, setSelectedImage, setFile }) {
  const [fileTypeError, setFileTypeError] = useState(null);
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFileTypeError(null);
      // Do something with the files
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          setFile(file);
          console.log('Image data URL:', reader.result); // Log the Data URL
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file); // Read file as Data URL
      });
    },
    [setSelectedImage],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected: (rejectedFiles) => {
      const unsupportedFiles = rejectedFiles.filter(
        (file) => !['image/png', 'image/jpeg', 'image/jpg'].includes(file.type),
      );

      if (unsupportedFiles.length > 0) {
        setFileTypeError(
          'Unsupported file type. Please select a PNG, JPEG, or JPG file.',
        );
      }
    },
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    maxSize: 1000 * 1024, // 1 MB
  });

  const removeImage = () => setSelectedImage(null); // Function to remove the image

  const handleCrossButtonClick = () => {
    setSelectedImage(false);
  };

  return (
    <div
      {...getRootProps()}
      style={{
        marginTop: '20px',
        height: '150px',
        width: '300px',
        borderRadius: '10px',
        border: !selectedImage ? '1px dashed grey' : ' ',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: !selectedImage ? '#FFF' : '#F7F7F7',
        padding: '10px', // Add padding inside the dropzone
      }}
    >
      <input {...getInputProps()} />
      {selectedImage ? (
        <div>
          <div className="d-flex justify-content-center ">
            <div className="position-relative">
              <img style={{ width: '40px', height: '40px' }} src={pdf} alt="" />
              <div
                className="position-absolute "
                style={{ right: '0px', top: '-5px' }}
              >
                <div onClick={handleCrossButtonClick}>
                  <img
                    style={{ width: '15px', height: '15px' }}
                    src={removeLogo}
                    alt="preview"
                  />
                </div>
              </div>
            </div>
          </div>
          <p className="mt-2"> Preview</p>
          <div
            className="d-flex  flex-column justify-content-center align-items-center"
            style={{
              background: '#fff',
              borderRadius: '10px',
            }}
          >
            <embed
              src={selectedImage}
              alt="preview"
              style={{ maxWidth: '50%', maxHeight: '60px' }} // Image will not overflow the container
            />
          </div>
        </div>
      ) : (
        <>
          {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
          <div style={{ color: '#aaa', marginTop: '10px' }}>
            Drag and drop your signature
          </div>
          <div
            style={{
              position: 'relative',
              maxWidth: '80%',
              maxHeight: '50%',
              marginTop: '10px',
            }}
          >
            <img
              style={{ maxWidth: '33px', maxHeight: '27px' }}
              src={Uploadimage}
              alt="Upload Image"
            />
          </div>
          <div>
            <BasicButton
              name="Save"
              className="mt-3"
              style={{
                backgroundColor: 'white',
                color: 'black',
                height: '25px',
              }}
              onClick={open}
            >
              Browse FIles
            </BasicButton>
          </div>
        </>
      )}
    </div>
  );
}

export default ImageUploader;
