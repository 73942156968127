import { Button } from 'react-bootstrap';

const BasicButton = ({
  style,
  outlined,
  children,
  onClick,
  size,
  name,
  disabled,
  id,
  className,
}) => {
  return (
    <Button
      style={style}
      disabled={disabled}
      name={name}
      size={size}
      onClick={onClick}
      className={`rounded-1  d-flex justify-content-center align-items-center px-2 ${className} basic-btn`}
      variant={outlined ? 'outline-dark' : 'dark'}
      id={id}
    >
      <div
        className="basic-btn-text  d-flex justify-content-center align-items-center"
        style={{ width: children.length > 9 && '130px' }}
        name={name}
        id={id}
      >
        {children}
      </div>
    </Button>
  );
};

export default BasicButton;
