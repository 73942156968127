import { FunctionComponent, useState } from 'react';
import Summary from './summary';
import TruckCard from './truckCard';
import Timeline from './timeline';
import { withAccessControl } from '../pages/service/with-access-control';
import { AssetProvider } from '../pages/service/assetContext';
import { DateRange } from 'react-day-picker';
import { currentDateNZ } from './helper';

const DmuDashboard: FunctionComponent = () => {
  const [finalRange, setFinalRange] = useState<DateRange | undefined>({
    from: currentDateNZ,
  });
  const [xAxisValueSelect, setxAxisValueSelect] = useState<
    string | undefined
  >();

  return (
    <AssetProvider>
      <div className="dmu-container" style={{ background: 'white' }}>
        <TruckCard />
        <Summary
          finalRange={finalRange}
          setFinalRange={setFinalRange}
          xAxisValueSelect={xAxisValueSelect}
          setxAxisValueSelect={setxAxisValueSelect}
        />
        <Timeline
          finalRange={finalRange}
          xAxisValueSelect={xAxisValueSelect}
          setxAxisValueSelect={setxAxisValueSelect}
        />
      </div>
    </AssetProvider>
  );
};

export default withAccessControl(DmuDashboard);
