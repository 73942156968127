import { Col, Image, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';

export const DumpEventPopup = (coordinate, { title, data }) => {
  const div = document.createElement('div');
  const dumpEvent = data.dumpEvent;
  const selectedtruck = data?.selectedtruck;

  // console.log(data, "datatat clicked on point");

  const popup = (
    <>
      <table
        className="dumpEventPopup popup-width-2"
        style={{ zIndex: 999 }}
        key={`popup-${coordinate.lat}-${coordinate.lng}`}
      >
        <thead>
          <tr>
            <th
              colSpan={2}
              style={{
                paddingTop: '0.5rem',
                width: '100%',
                textAlign: 'left',
              }}
            >
              <div
                className="d-flex"
                style={{ justifyContent: 'space-between' }}
              >
                <div className="d-flex align-items-center  px-4">
                  <div className="">
                    <Image
                      style={{
                        height: '1.3rem',
                      }}
                      src={'../../assets/images/material_breakdown.svg'}
                    />
                  </div>
                  <div
                    className=" px-2"
                    style={{
                      padding: 0,
                      margin: 0,
                      color: 'black',
                    }}
                  >
                    <span style={{ fontSize: '1.25rem' }}>Material Type</span>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <hr
          style={{ padding: '0', margin: '0', width: 'calc(100% - 0.25rem)' }}
        />
        <tbody style={{ width: '8rem' }}>
          <div className=" d-flex flex-column px-4 ">
            <Row className="">
              {' '}
              <Col className="col-4">
                <span style={{ color: '#707070' }}> Trucks: </span>
              </Col>
              <Col className="col-8">
                <strong> {data?.dumpPointName}</strong>
              </Col>
            </Row>
            <Row className="">
              {' '}
              <Col className="col-4">
                <span style={{ color: '#707070' }}> From: </span>
              </Col>
              <Col className="col-8">
                <strong> Unknown </strong>
              </Col>
            </Row>
            <Row className="">
              {' '}
              <Col className="col-4">
                <span style={{ color: '#707070' }}> At: </span>
              </Col>
              <Col className="col-8">
                <strong> 10:00 AM</strong>
              </Col>
            </Row>
          </div>

          <tr>
            <td style={{ paddingBottom: '1rem' }} />
          </tr>
        </tbody>
      </table>
      {/* <div className="mm-popup-tip-container"></div> */}
    </>
  );

  ReactDOM.render(popup, div);
  return div;
};
