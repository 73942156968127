const Loader = () => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <tr>
          <td
            style={{
              margin: 'auto',
            }}
          >
            Loading data...
          </td>
        </tr>
        <tr>
          <td style={{ paddingBottom: '1rem' }} />
        </tr>
      </div>
    </>
  );
};

export default Loader;
