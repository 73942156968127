import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import Select from 'react-select';
import { GET_MATERIALS } from './action/apiPath';
import { convertCSVToJson } from './utils/helper';
import CreatePointTable from './CreatePointTable';
import upload from './assets/images/upload.svg';
import { CallWithAuth } from './action/apiActions';

export default function PolygonInfo(props) {
  const {
    type = 'source',
    polygon,
    onChange = () => {},
    showdummy = true,
    setSelectPoint = () => {},
    handleCursor = () => {},
    clearPolygon = () => {},
    requiredMesage = '',
    onSubmit = () => {},
  } = props;
  const inputRef = useRef(null);

  const [newDumpPoint, setNewDumpPoint] = useState(polygon);
  const [data, setData] = useState(polygon?.coordinates);
  const [showdummypoints, setShowDummyPoints] = useState(showdummy);
  const [materials, setMaterials] = useState([]);
  const [canProceed, setCanProceed] = useState([]);
  const [fieldChanged, setFieldChanged] = useState(false);
  const fetchMaterials = async () => {
    try {
      const response = await CallWithAuth('GET', GET_MATERIALS);
      if (response?.res?.data?.status === 200) {
        setMaterials(response?.res?.data?.data);
      } else throw response?.res;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchMaterials();
  }, []);
  useEffect(() => {
    if (polygon?.coordinates?.length > 2) {
      const hasInvalidCoordinates = polygon.coordinates.some((coord) => {
        return typeof coord.lat !== 'number' || typeof coord.lng !== 'number';
      });

      if (hasInvalidCoordinates) {
        setCanProceed(false);
      } else {
        setCanProceed(true);
      }
    } else {
      setCanProceed(false);
    }
  }, [polygon]);

  // console.log(polygon, "polygon imosde Ploygon Info");

  useEffect(() => {
    if (
      JSON.stringify(newDumpPoint?.coordinates) !==
      JSON.stringify(polygon?.coordinates)
    )
      setNewDumpPoint({ ...newDumpPoint, coordinates: polygon?.coordinates });
  }, [polygon]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    try {
      const jsonData = await convertCSVToJson(file);
      let updatedArray = [];
      for (let i = 0; i < jsonData?.length; i++) {
        let e = jsonData[i];
        if (e?.latitude && e?.longitude) {
          updatedArray.push({
            lat: Number(parseFloat(e?.latitude).toFixed(6)),
            lng: Number(parseFloat(e?.longitude).toFixed(6)),
          });
        }
      }
      setData(updatedArray);
      setNewDumpPoint({ ...newDumpPoint, coordinates: updatedArray });
      onChange({ ...polygon, coordinates: updatedArray, onlyHighlight: true });
      setShowDummyPoints(false);
    } catch (error) {
      // console.error("Error converting CSV to JSON:", error);
    }
  };
  const handleParse = () => {
    inputRef.current.click();
  };
  const onSelect = (option, selected) => {
    // console.log(selected, "selected in 110");

    // console.log("selected")
    setNewDumpPoint((prev) => {
      return { ...prev, [option]: selected };
    });
  };

  // console.log(data, newDumpPoint, "datat newDumpPoint");

  // if (!created && !dumpPoint.name) return <></>;

  // console.log(fieldChanged, "fieldChanged ")
  return (
    <>
      <div
        className="mx-3 px-1"
        style={{
          // overflowY: data?.length >= 2 ? "auto" : "hidden",
          overflowY: 'scroll',
          cursor: 'pointer',
          overflowX: 'hidden',
          top: '4.7rem',
          left: '0',
          position: 'absolute',
          display: 'flex',
          flexwrap: 'wrap',
          justifyContent: 'space-between',
          width: '600px',
          height: polygon?.coordinates?.length > 2 ? '80vh' : 'max-content',
          // padding: "28px 30px",
          background: ' #FFFFFF 0% 0% no-repeat padding-box',
          boxShadow: ' 0px 3px 6px #00000029',
          borderRadius: '0.85rem',
          zIndex: 2,
        }}
      >
        <div
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              maxHeight: '98%',
              overflowY: 'scroll',
            }}
          >
            <div
              className="d-flex justify-content-between "
              style={{
                width: '590px',
                borderRadius: '0.85rem 0.85rem 0 0',
                position: 'fixed',
                backgroundColor: '#fff',
                padding: '20px 15px 15px 15px',
                borderBottom: '1px solid #EBEBEB',
              }}
            >
              <div className=" mm-source-modal-heading">
                {newDumpPoint?._id ? <span>EDIT</span> : <span>CREATE</span>}{' '}
                {type === 'source' ? 'SOURCE POINT' : 'DUMP POINT'}
              </div>
            </div>

            <div
              style={{ borderBottom: '1px solid #EBEBEB', margin: '0px 0px' }}
            ></div>

            <div style={{ marginTop: '4rem', padding: '0px 20px' }}>
              <div
                style={{
                  fontSize: '1rem',
                  padding: '10px 0px',
                  width: '100%',
                  color: 'black',
                  fontWeight: 600,
                }}
              >
                {newDumpPoint?._id
                  ? 'Edit coordinates or modify it on the map'
                  : 'Insert coordinates or draw geofence on the map'}
              </div>

              <input
                type="file"
                accept=".csv"
                style={{ display: 'none' }}
                ref={inputRef}
                id="csvInput"
                onChange={handleFileChange}
              />

              <button
                className="btn save"
                onClick={handleParse}
                style={{
                  width: '10rem',
                  height: '2.5rem',
                  font: 'normal normal bold 12px/17px Open Sans',
                  border: ' 1px solid black',
                  background: '#2B2D2F 0% 0% no-repeat padding-box',
                  borderRadius: 4,
                  color: 'white',
                  fontSize: '1rem',
                  alignItems: 'center',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                <Image
                  src={upload}
                  className="mx-2"
                  style={{ height: '1rem', width: '1rem' }}
                />
                <span style={{ fontSize: '1rem' }}> Upload CSV</span>{' '}
              </button>

              <div style={{ marginTop: '2.1rem' }}>
                <CreatePointTable
                  setFieldChanged={setFieldChanged}
                  showdummypoints={
                    showdummypoints && polygon?.coordinates?.length < 3
                  }
                  setShowDummyPoints={setShowDummyPoints}
                  coordinates={polygon?.coordinates}
                  setSelectedPoint={(v) => setSelectPoint(v)}
                  onChange={(coordinates) => {
                    console.log(
                      '===============---------------=============',
                      coordinates,
                    );
                    setNewDumpPoint({
                      ...newDumpPoint,
                      coordinates: coordinates,
                    });
                    onChange({
                      ...polygon,
                      coordinates,
                      mode: 'create',
                      visible: true,
                      onlyHighlight: true,
                    });
                  }}
                />
              </div>

              {/* </div> */}
              <div
                style={{
                  borderTop: data?.length > 2 ? '1px solid #EBEBEB' : '',
                  // margin: "0px -30px",
                }}
              ></div>
              {polygon?.coordinates?.length > 2 ? (
                <div style={{ marginTop: '2.1rem' }}>
                  <div>
                    <p
                      style={{
                        font: 'normal normal 600 16px/22px Open Sans',
                        fontSize: '1.33rem',
                      }}
                    >
                      Details
                    </p>
                  </div>
                  <div
                    className="my-2"
                    style={{ width: '100%', display: 'flex' }}
                  >
                    <div
                      style={{
                        font: 'normal normal normal 14px/19px Open Sans',
                        fontSize: '1.15rem',
                        color: '#1A1A1A',
                        alignSelf: 'center',
                        width: '20%',
                      }}
                    >
                      <h6>Name*</h6>
                    </div>
                    <div style={{ width: '80%' }}>
                      <input
                        style={{
                          background: '#FFFFFF 0% 0% no-repeat padding-box',
                          border: '1px solid #EBEBEB',
                          borderRadius: 6,
                          width: '100%',
                          fontSize: '1.15rem',
                          fontWeight: 600,
                          height: '90%',
                        }}
                        value={newDumpPoint?.name}
                        onChange={(e) => {
                          setFieldChanged(true);
                          setNewDumpPoint({
                            ...newDumpPoint,
                            name: e.target.value,
                          });
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  <div
                    className="my-2"
                    style={{ width: '100%', display: 'flex' }}
                  >
                    <div
                      style={{
                        font: 'normal normal normal 14px/19px Open Sans',
                        fontSize: '1.15rem',
                        color: '#1A1A1A',
                        alignSelf: 'center',
                        width: '20%',

                        height: '90%',
                      }}
                    >
                      <h6>Material*</h6>
                    </div>
                    <div className="mm-custom_select" style={{ width: '80%' }}>
                      <Select
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            padding: '0',
                            // margin: "3.7px",
                            fontSize: '1.1rem',
                            border: '1px solid #EBEBEB',
                            borderRadius: '6px',
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            width: '100%',
                            maxHeight: '230px',
                            overflowY: 'auto',
                            fontSize: '1.1rem',
                            overflowX: 'hidden',
                          }),

                          valueContainer: (provided, state) => ({
                            ...provided,
                            // height: "2.2rem",
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            alignItems: 'center',
                            padding: '0rem 0px',
                          }),
                          indicatorsContainer: (provided, state) => ({
                            ...provided,
                            height: '3rem',
                            fontSize: '1rem',
                            size: '1rem',
                          }),
                          indicatorSeparator: (state) => ({
                            display: 'none',
                          }),
                        }}
                        onChange={(selected) => {
                          setFieldChanged(true);
                          onSelect('materials', selected);
                        }}
                        defaultValue={newDumpPoint?.materials}
                        isMulti
                        name="colors"
                        options={materials?.map((e) => {
                          return {
                            label: e?.MaterialType,
                            value: e?.MaterialType,
                          };
                        })}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                  </div>

                  <div
                    className="my-2"
                    style={{ width: '100%', display: 'flex' }}
                  >
                    <div
                      style={{
                        font: 'normal normal normal 14px/19px Open Sans',
                        color: '#1A1A1A',
                        alignSelf: 'center',
                        width: '20%',
                        height: '90%',
                      }}
                    >
                      <h6>Description</h6>
                    </div>
                    <div style={{ width: '80%' }}>
                      <textarea
                        rows="4"
                        style={{
                          background: '#FFFFFF 0% 0% no-repeat padding-box',
                          border: '1px solid #EBEBEB',
                          borderRadius: 4,
                          width: '100%',
                          resize: 'none',
                        }}
                        value={newDumpPoint?.description}
                        onChange={(e) => {
                          setFieldChanged(true);
                          setNewDumpPoint({
                            ...newDumpPoint,
                            description: e.target.value,
                          });
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              ) : null}

              <div
                className="d-flex"
                style={{ width: '100%', justifyContent: 'space-between' }}
              >
                <div>
                  <p
                    style={{
                      font: 'normal normal normal 10px/14px Open Sans',
                      color: '#B14747',
                    }}
                  >
                    {requiredMesage}
                  </p>
                </div>
                <div className="d-flex justify-content-between my-5">
                  <button
                    style={{
                      width: '6.7rem',
                      height: '2rem',
                      font: 'normal normal bold 12px/17px Open Sans',
                      color: '#2B2D2F',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      justifyContent: 'center',
                      border: ' 1px solid black',
                      borderRadius: 4,
                      fontSize: '1rem',
                    }}
                    className="btn discard btn-focus mx-3"
                    onClick={(v) => {
                      clearPolygon();
                      setSelectPoint({
                        coordinate: {
                          lat: 0.0,
                          lng: 0.0,
                        },
                        index: 0,
                      });
                      onChange({});
                      props.close(v);
                      handleCursor(2);
                    }}
                    // onClick={onCancel}
                  >
                    Discard
                  </button>
                  <button
                    className="btn save ml-3 btn-focus"
                    onClick={() => onSubmit(newDumpPoint)}
                    disabled={newDumpPoint?._id ? !fieldChanged : !canProceed}
                    style={{
                      width: '6.7rem',
                      height: '2rem',
                      font: 'normal normal bold 12px/17px Open Sans',
                      border: ' 1px solid black',
                      background: '#2B2D2F 0% 0% no-repeat padding-box',
                      borderRadius: 4,
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      justifyContent: 'center',
                      fontSize: '1rem',
                    }}
                  >
                    {newDumpPoint?._id ? 'Edit' : 'Create'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
