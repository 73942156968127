import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useSmartReducer } from './useSmartReducer';

export const useIntervalFetch = (
  name,
  fetch,
  initialState,
  interval = 10000,
) => {
  const [fetchNow, setFetchNow] = useState(false);
  const triggerFetch = () => {
    console.debug(`${name}: Immediate fetching triggered`);
    setFetchNow((prev) => !prev);
  };

  const [state, dispatch] = useSmartReducer(initialState);

  const intervalRef = useRef(null);
  const intervalCounter = useRef(1);

  useEffect(() => {
    try {
      console.debug(`${name}: Initial fetch`);
      fetch(dispatch);

      intervalRef.current = setInterval(() => {
        console.debug(
          `${name}: Refreshing data. Count: ${intervalCounter.current}`,
        );
        fetch(dispatch);
        intervalCounter.current += 1;
      }, interval);
    } catch (error) {
      toast.error('An error occurred while fetching the data', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,

        // Following are default but kept to avoid
        // the code breaking if the library changes
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    return () => {
      clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNow]);

  return [
    {
      ...state,
      triggerFetch,
    },
    dispatch,
  ];
};
