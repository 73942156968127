import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
//fonts
import './fonts/Helvetica-Neue.ttf';
import { AuthContextProvider } from './pages/service/auth';
import App from './App';
import { AuthProvider } from './materialMovements/context/AuthProvider';
import ErrorBoundary from './materialMovements/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode> // Strict mode commented so that drag and drop on manage status work in dev
  <BrowserRouter>
    <AuthContextProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </AuthContextProvider>
  </BrowserRouter>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
