import { useContext, useMemo, useState, useEffect } from 'react';
import { UserContext } from './userContext';
import Unauthorized from '../unauthorized';
import Sidebar from '../Header/Sidebar';
import Header from '../Header';
import { sections, subSections } from '../utils/constants';
import { startCase } from 'lodash';
import { useGlobalContext } from './GlobalContext';

export const withAccessControl = (Component, defaultSelectedTab) => (props) => {
  const { code: departmentCode } = props;
  const { userInfo, departments } = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState(-1);
  const [sidebarActive, setSidebarActive] = useState(true);
  const [selectedSubText, setSelectedSubText] = useState('');
  const [showBack, setShowBack] = useState(false);
  const { showLiveAssetTracking, setShowLiveAssetTracking } =
    useGlobalContext();

  const getSelectedTabFromUrl = () => {
    return defaultSelectedTab ?? 0;
  };

  useEffect(() => {
    setSelectedTab(getSelectedTabFromUrl());
  }, []);

  useEffect(() => {
    if (showLiveAssetTracking && departmentCode === 800) {
      setSelectedSubText('Live Tracking');
      setShowBack(true);
    } else {
      setShowLiveAssetTracking(false);
      setShowBack(false);
      const subTexts = subSections[departmentCode];
      if (subTexts && subTexts.length > 0) {
        setSelectedSubText(startCase(subTexts[selectedTab] || subTexts[0]));
      } else {
        setSelectedSubText('');
      }
    }
  }, [
    departmentCode,
    selectedTab,
    showLiveAssetTracking,
    setShowLiveAssetTracking,
  ]);

  const handleOnBack = () => {
    if (showLiveAssetTracking && departmentCode === 800) {
      setShowLiveAssetTracking(false);
      setShowBack(false);
    }
  };

  const department = useMemo(() => {
    return departments?.find(({ code }) => code === departmentCode);
  }, [departments, departmentCode]);
  if (
    !userInfo.departments?.includes(department?.code) &&
    department?.is_restricted
  )
    return <Unauthorized />;
  else
    return (
      <div className="main-container">
        <Sidebar
          code={departmentCode}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          subSections={subSections[departmentCode]}
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
        <div
          className={`${
            departmentCode !== 200
              ? 'inner-container'
              : 'inner-container-not-overflow'
          }`}
        >
          <Header
            showDropDown
            title={sections[departmentCode]}
            code={departmentCode}
            selectedSubText={selectedSubText}
            selectedTab={selectedTab}
            showBack={showBack}
            onBack={handleOnBack}
          />
          <Component
            {...props}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      </div>
    );
};
