import { useEffect, useState } from 'react';
import { Asset, SelectedAsset, TruckType } from '../model';
import styles from '../summary/Summary.module.css';

type Props = {
  assets: Asset[];
  selectedAssets: SelectedAsset[];
  handleApply: Function;
};

const MultiAssetSelect = ({ assets, selectedAssets, handleApply }: Props) => {
  const [assetsCheck, setAssetsCheck] = useState<SelectedAsset[]>([]);

  useEffect(() => {
    setAssetsCheck(
      selectedAssets.filter(
        (selectedAsset: SelectedAsset) => selectedAsset.type !== TruckType.DMU,
      ),
    );
  }, [selectedAssets]);

  return (
    <div className={styles.assets}>
      <div className={styles.textSm}>Select Multiple Dozers:</div>
      {assets.map((asset: Asset, index: number) => {
        if (index === 0) {
          return <></>;
        }
        return (
          <div
            key={index}
            className={`${styles.assetCheck} ${
              assetsCheck.findIndex(
                (assetCheck: SelectedAsset) => assetCheck.name === asset.name,
              ) !== -1
                ? styles.checked
                : ''
            }`}
            onClick={() => {
              if (
                assetsCheck.findIndex(
                  (assetCheck: SelectedAsset) => assetCheck.name === asset.name,
                ) === -1
              ) {
                setAssetsCheck((prev) => {
                  return [...prev, { name: asset.name, type: asset.type }];
                });
              } else {
                setAssetsCheck((prev: SelectedAsset[]) => {
                  return prev.filter(
                    (selectedAsset: SelectedAsset) =>
                      selectedAsset.name !== asset.name,
                  );
                });
              }
            }}
          >
            <input
              type="checkbox"
              name="asset"
              checked={
                assetsCheck.findIndex(
                  (assetCheck: SelectedAsset) => assetCheck.name === asset.name,
                ) !== -1
              }
              className={styles.checkbox}
            />
            {asset.name}
          </div>
        );
      })}
      <button
        className={`${styles.btn} ${styles.fill} ${styles.btnSm} ${
          JSON.stringify(selectedAssets) === JSON.stringify(assetsCheck) ||
          assetsCheck.length === 0
            ? styles.disabledBtn
            : ''
        }`}
        onClick={() => {
          if (
            JSON.stringify(selectedAssets) === JSON.stringify(assetsCheck) ||
            assetsCheck.length === 0
          ) {
            return;
          }
          handleApply(assetsCheck);
        }}
      >
        Apply
      </button>
    </div>
  );
};

export default MultiAssetSelect;
