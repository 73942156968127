import { RefObject, useEffect, useRef, useState } from 'react';
import {
  ProductivityTimeline,
  TimelineGranularity,
  TimelineKeys,
  TimelineTypes,
  TimeUseTimeline,
  UtilisationTimeline,
} from './models';
import { getGraphKeys } from './helper';
import StatusDot from '../../pages/components/StatusDot';
import LeastMostSummary from './timelineData';
import styles from './Timeline.module.css';
import NewGraphs from './newGraphs';
import { TruckType } from '../model';
import { DateRange } from 'react-day-picker';
import { useAssetContext } from '../../pages/service/assetContext';
import { values } from 'lodash';
import useMediaQuery from '../../hooks/useMediaQuery';
import { maxHeightBreakPoint } from '../constants';

type Props = {
  finalRange: DateRange | undefined;
  setxAxisValueSelect: Function;
  xAxisValueSelect: string | undefined;
};

const Timeline = ({
  finalRange,
  setxAxisValueSelect,
  xAxisValueSelect,
}: Props) => {
  const [type, setType] = useState<TimelineTypes>(TimelineTypes.Productivity);
  const [granularity, setGranularity] = useState<TimelineGranularity>(
    TimelineGranularity.Hourly,
  );
  const { selectedAssets } = useAssetContext();
  const graphKeys = getGraphKeys(type, selectedAssets[0]?.type);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<
    ProductivityTimeline | TimeUseTimeline | UtilisationTimeline
  >([]);

  useEffect(() => {
    if (selectedAssets[0]?.type === TruckType.DMU) {
      setType(TimelineTypes.Productivity);
    }
  }, [selectedAssets]);
  const [width, setWidth] = useState<number>(0);
  const elementRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element: HTMLDivElement | null = elementRef.current;
    const updateWidth = (): void => {
      if (element) {
        setWidth(element.offsetWidth);
      }
    };
    const resizeObserver: ResizeObserver = new ResizeObserver(updateWidth);
    if (element) {
      resizeObserver.observe(element);
    }
    updateWidth();
    return () => {
      if (resizeObserver && element) {
        resizeObserver.unobserve(element);
      }
    };
  }, []);

  const { height } = useMediaQuery();

  return (
    <div style={{ marginTop: '20px' }}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {selectedAssets[0]?.type === TruckType.DMU ? (
            <div
              className={`tab highlighted-tab`}
              style={{
                fontSize: height <= maxHeightBreakPoint ? '12px' : '14px',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
              }}
            >
              {TimelineTypes.Productivity}
            </div>
          ) : (
            values(TimelineTypes).map((timelineType: TimelineTypes, index) => (
              <div
                key={index}
                className={`tab ${
                  timelineType === type ? 'highlighted-tab' : ''
                }`}
                onClick={() => setType(timelineType)}
                style={{
                  fontSize: height <= maxHeightBreakPoint ? '12px' : '14px',
                  borderTopLeftRadius: index === 0 ? '4px' : '',
                  borderTopRightRadius:
                    index === values(TimelineTypes).length - 1 ? '4px' : '',
                }}
              >
                {timelineType} {timelineType === type ? 'Progression' : ''}
              </div>
            ))
          )}
        </div>
      </div>

      <div
        className="timeline-container"
        style={{ position: 'relative' }}
        ref={elementRef}
      >
        {!loading && (
          <div
            className={`d-flex w-100 justify-content-between ${
              height <= maxHeightBreakPoint ? 'mb-1' : 'mb-2'
            }`}
          >
            <LeastMostSummary
              type={type}
              granularity={granularity}
              finalRange={finalRange}
              data={data}
            />
            <div className="d-flex gap-4 align-items-center">
              {graphKeys.map(({ name, color }: TimelineKeys, index) => (
                <StatusDot key={index} color={color}>
                  <span
                    style={{
                      fontSize: height <= maxHeightBreakPoint ? '10px' : '12px',
                    }}
                  >
                    {name}
                  </span>
                </StatusDot>
              ))}
              <div className={styles.granularityToggle}>
                {values(TimelineGranularity).map(
                  (timelineGranuality: TimelineGranularity, index) => (
                    <div
                      key={index}
                      className={`${
                        granularity === timelineGranuality
                          ? styles.selectedToggle
                          : styles.unselectedToggle
                      }`}
                      onClick={() => setGranularity(timelineGranuality)}
                    >
                      {timelineGranuality}
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        )}
        <NewGraphs
          granularity={granularity}
          type={type}
          finalRange={finalRange}
          setLoading={setLoading}
          loading={loading}
          data={data}
          setData={setData}
          xAxisValueSelect={xAxisValueSelect}
          setxAxisValueSelect={setxAxisValueSelect}
          parentWidth={width}
        />
      </div>
    </div>
  );
};

export default Timeline;
