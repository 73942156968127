import { FunctionComponent } from 'react';
import { IconProps } from '../../helper/types';

const ReportIncompleteIcon: FunctionComponent<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.size ?? '30'}
      height={props.size ?? '30'}
      viewBox="0 0 39 39"
    >
      <defs>
        <filter
          id="Ellipse_3538"
          x="0"
          y="0"
          width="39"
          height="39"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_51385"
        data-name="Group 51385"
        transform="translate(-252 -203.662)"
      >
        <g
          transform="matrix(1, 0, 0, 1, 252, 203.66)"
          filter="url(#Ellipse_3538)"
        >
          <g
            id="Ellipse_3538-2"
            data-name="Ellipse 3538"
            transform="translate(9 7)"
            fill="#fff"
            stroke="#325d88"
            strokeWidth="1"
          >
            <circle cx="10.5" cy="10.5" r="10.5" stroke="none" />
            <circle cx="10.5" cy="10.5" r="10" fill="none" />
          </g>
        </g>
        <g id="Group_51228" data-name="Group 51228" transform="translate(-1 1)">
          <line
            id="Line_5949"
            data-name="Line 5949"
            x1="7"
            y2="7"
            transform="translate(269 216.662)"
            fill="none"
            stroke="#325d88"
            strokeLinecap="round"
            strokeWidth="1.4"
          />
          <line
            id="Line_5987"
            data-name="Line 5987"
            x1="7"
            y1="7"
            transform="translate(269 216.662)"
            fill="none"
            stroke="#325d88"
            strokeLinecap="round"
            strokeWidth="1.4"
          />
        </g>
      </g>
    </svg>
  );
};

export default ReportIncompleteIcon;
