import React from 'react';

interface FuelProps {
  height?: number;
  width?: number;
}

const Fuel: React.FC<FuelProps> = ({ height = 16, width = 11.5 }) => {
  return (
    <svg
      viewBox="0 0 11.5 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#808080"
      height={height}
      width={width}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_11685"
            data-name="Rectangle 11685"
            width="11.5"
            height="16"
            fill="none"
            stroke="#5b5b5b"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g id="Group_52186" data-name="Group 52186" clip-path="url(#clip-path)">
        <path
          id="Path_21219"
          data-name="Path 21219"
          d="M8,12.5v-3a1.5,1.5,0,0,1,0,3ZM8,8H7.926A3.752,3.752,0,0,0,4.25,5a3.649,3.649,0,0,0-1.965.562A5.252,5.252,0,0,1,7.25,2H8V.5H7.25A6.751,6.751,0,0,0,.5,7.25V15.5H8V14A3,3,0,0,0,8,8Z"
          fill="none"
          stroke="#5b5b5b"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default Fuel;
