import { FunctionComponent } from 'react';

import { PreCheckEvaluationResult } from './models/enums';

import {
  DefectColor,
  InitialPrestartcheckDefectCountType,
  PreCheckEvaluationResultLabels,
} from './helper/constants';

import ReportCategoryIcon from './assets/icons/report-category';
import ReportPassedIcon from './assets/icons/reportPassed';
import ReportIncompleteIcon from './assets/icons/reportIncomplete';
import { CategoryDefectWithIcon, IconProps } from './helper/types';

export const categoryDefects: CategoryDefectWithIcon[] = [
  {
    name: PreCheckEvaluationResult.CATEGORY_A_FAILURE,
    Icon: (props: IconProps) => (
      <ReportCategoryIcon
        size={props.size}
        color={DefectColor[PreCheckEvaluationResult.CATEGORY_A_FAILURE]}
        text={'A'}
      />
    ),
  },
  {
    name: PreCheckEvaluationResult.CATEGORY_B_FAILURE,
    Icon: (props: IconProps) => (
      <ReportCategoryIcon
        size={props.size}
        color={DefectColor[PreCheckEvaluationResult.CATEGORY_B_FAILURE]}
        text={'B'}
      />
    ),
  },
  {
    name: PreCheckEvaluationResult.CATEGORY_C_FAILURE,
    Icon: (props: IconProps) => (
      <ReportCategoryIcon
        size={props.size}
        color={DefectColor[PreCheckEvaluationResult.CATEGORY_C_FAILURE]}
        text={'C'}
      />
    ),
  },
  {
    name: PreCheckEvaluationResult.PASS,
    Icon: (props: IconProps) => <ReportPassedIcon size={props.size} />,
  },
  {
    name: PreCheckEvaluationResult.INCOMPLETE,
    Icon: (props: IconProps) => <ReportIncompleteIcon size={props.size} />,
  },
];

type DisplayCategoryDefectSummaryProps = {
  summary: InitialPrestartcheckDefectCountType;
};
const DisplayCategoryDefectsSummary: FunctionComponent<
  DisplayCategoryDefectSummaryProps
> = (props) => {
  return (
    <div className="d-flex gap-2 ">
      {categoryDefects.map(({ Icon, name }) => (
        <div
          className="d-flex justify-content-center align-items-center gap-1 "
          key={name}
        >
          <Icon />
          <p className="text-2 me-2 fw-bold text-capitalize">
            {' '}
            {props.summary[name]} {PreCheckEvaluationResultLabels[name]}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DisplayCategoryDefectsSummary;
