import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const generateDumpEventsPDF = (
  title,
  mainElementId,
  scrollableElementId,
) => {
  let element = document.getElementById(mainElementId);
  let scrollableElement = document.getElementById(scrollableElementId);

  const parentElementStyles = {
    height: element.getBoundingClientRect().height + 'px',
  };
  const scrollableElementStyles = {
    height: scrollableElement.getBoundingClientRect().height + 'px',
    overflowY: 'auto',
  };

  // Set new styles for printing
  element.style.height = 'max-content';
  scrollableElement.style.overflowY = 'visible';
  scrollableElement.style.height = 'max-content';

  if (element && element.style.display !== 'none') {
    html2canvas(element).then((canvas) => {
      let pdf = new jsPDF({
        unit: 'px',
        format:
          scrollableElement.scrollHeight > scrollableElement.clientHeight
            ? [canvas.width, canvas.height]
            : [1920, 875],
      });
      pdf.addImage(
        canvas.toDataURL('image/png'),
        'PNG',
        0,
        0,
        canvas.width,
        canvas.height,
      );

      const currentTime = new Date().toLocaleString();
      pdf.save(`${title}-${currentTime}.pdf`);
    });
  } else {
    throw new Error('Element not found or not visible');
  }

  // Revert the styles
  for (let style in scrollableElementStyles) {
    scrollableElement.style[style] = scrollableElementStyles[style];
  }
  for (let style in parentElementStyles) {
    element.style[style] = parentElementStyles[style];
  }
};
