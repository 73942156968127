import { useEffect, useState } from 'react';

import {
  GET_ALL_OPERATOR_FOR_SHIFTS,
  GET_DUMP_EVENTS,
  GET_MATERIALS,
  GET_SOURCE_DUMPS,
  GET_VEHICLE_EVENTS,
  SHIFT_DASHBOARD_LISTING_DETAILS,
} from './action/apiPath';
import { useIntervalFetch } from './hooks/useIntervalFetch';
import { CallWithAuth } from './action/apiActions';

const initialState = {
  dumpEvents: [],
  ongoingDumpEvents: [],
  previousDumpEvents: [],

  vehicleEvents: [],
  ongoingVehicleEvents: [],
  previousVehicleEvents: [],

  dumpPoints: [],
  optionsForDumpPoints: [],

  materials: [],
  optionsForMaterials: [],

  operators: [],
  optionsForOperators: [],
  pointsType: '',
  trucks: [],
  optionsForTrucks: [],
};

export const FetchDumpPointsData = () => {
  const [state, dispatch] = useIntervalFetch(
    'Dump Points',
    fetch,
    initialState,
    60000,
  );

  // 0 = Ongoing Shift
  // 1 = Previous Shift
  const [shiftChoice, setShiftChoice] = useState('0');
  useEffect(() => {
    switch (shiftChoice) {
      case '0':
        dispatch({ type: 'dumpEvents', payload: state?.ongoingDumpEvents });
        dispatch({
          type: 'vehicleEvents',
          payload: state?.ongoingVehicleEvents,
        });
        break;

      case '1':
        dispatch({ type: 'dumpEvents', payload: state?.previousDumpEvents });
        dispatch({
          type: 'vehicleEvents',
          payload: state?.previousVehicleEvents,
        });
        break;
    }
  }, [
    state?.ongoingDumpEvents,
    state?.previousDumpEvents,
    state?.ongoingVehicleEvents,
    state?.previousVehicleEvents,
    shiftChoice,
  ]);

  const [isDetailPageOpen, setIsDetailPageOpen] = useState(false);
  const [selectedDumpPoint, setSelectedDumpPoint] = useState({});
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [pointtype, setpointType] = useState('');

  // useEffect(() => {
  //   if (selectedDumpPoint?._id) {
  //     setSelectedDumpPoint(
  //       state?.dumpEvents?.find(
  //         (dumpEvent) => dumpEvent?._id === selectedDumpPoint?._id
  //       )
  //     );
  //   } else if (selectedVehicle?._id) {
  //     setSelectedVehicle(
  //       state?.vehicleEvents?.find(
  //         (vehicleEvent) => vehicleEvent?._id === selectedVehicle?._id
  //       )
  //     );
  //   }
  // }, [selectedDumpPoint?._id, selectedVehicle?._id, state?.dumpEvents, state?.vehicleEvents]);
  useEffect(() => {
    if (selectedDumpPoint?._id) {
      setSelectedDumpPoint(
        state?.dumpEvents?.find(
          (dumpEvent) => dumpEvent?._id === selectedDumpPoint?._id,
        ),
      );
    } else if (selectedVehicle?._id) {
      setSelectedVehicle(
        state?.vehicleEvents?.find(
          (vehicleEvent) => vehicleEvent?._id === selectedVehicle?._id,
        ),
      );
    }
  }, [state?.dumpEvents, state?.vehicleEvents]);

  return {
    setpointType,
    isDetailPageOpen,
    setIsDetailPageOpen,
    selectedDumpPoint,
    setSelectedDumpPoint,
    selectedVehicle,
    setSelectedVehicle,
    shiftChoice,
    setShiftChoice,
    ...state,
  };
};

const fetch = (dispatch) => {
  const fetchDumpEvents = async () => {
    try {
      const response = await CallWithAuth('GET', GET_DUMP_EVENTS);
      if (response?.res?.data?.status === 200) {
        dispatch({
          type: 'ongoingDumpEvents',
          payload: response?.res?.data?.data[0],
        });
        dispatch({
          type: 'previousDumpEvents',
          payload: response?.res?.data?.data[1],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDumpPoints = async () => {
    // try {
    //   const response = await CallWithAuth(
    //     "GET",
    //     `${GET_SOURCE_DUMPS}?status=Active`
    //   );
    //   if (response?.res?.data?.status === 200) {
    //     const dumpPoints = response?.res?.data?.data;
    //     const optionsForDumpPoints = dumpPoints?.map((dumpPoint) => {
    //       return { name: dumpPoint?.name, value: dumpPoint?._id };
    //     });
    //     dispatch({ type: "dumpPoints", payload: dumpPoints });
    //     dispatch({
    //       type: "optionsForDumpPoints",
    //       payload: optionsForDumpPoints,
    //     });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const fetchMaterials = async () => {
    try {
      const response = await CallWithAuth('GET', GET_MATERIALS);
      if (response?.res?.data?.status === 200) {
        const materials = response?.res?.data?.data;
        const optionsForMaterials = materials?.map((material) => {
          return { name: material?.MaterialType, value: material?._id };
        });
        dispatch({ type: 'materials', payload: materials });
        dispatch({
          type: 'optionsForMaterials',
          payload: optionsForMaterials,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchVehicleEvents = async () => {
    try {
      const response = await CallWithAuth('GET', GET_VEHICLE_EVENTS);
      if (response?.res?.data?.status === 200) {
        dispatch({
          type: 'ongoingVehicleEvents',
          payload: response?.res?.data?.data[0],
        });
        dispatch({
          type: 'previousVehicleEvents',
          payload: response?.res?.data?.data[1],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOperators = async () => {
    try {
      const response = await CallWithAuth('GET', GET_ALL_OPERATOR_FOR_SHIFTS);
      if (response?.res?.data?.status === 200) {
        const operators = response?.res?.data?.data;
        const optionsForOperators = [];
        optionsForOperators?.push(
          operators[0]?.map((operator) => {
            return {
              name: operator?.firstName + ' ' + operator?.lastName,
              value: operator?._id,
            };
          }),
        );
        optionsForOperators?.push(
          operators[1]?.map((operator) => {
            return {
              name: operator?.firstName + ' ' + operator?.lastName,
              value: operator?._id,
            };
          }),
        );
        dispatch({ type: 'operators', payload: optionsForOperators });
        dispatch({
          type: 'optionsForOperators',
          payload: optionsForOperators,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchDumpEvents();
  fetchDumpPoints();
  fetchMaterials();
  // fetchTrucks();
  fetchOperators();
  fetchVehicleEvents();
};
