import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import BasicButton from '../components/BasicButton';

const Unauthorized = () => {
  const navigate = useNavigate();
  const handleLandingPageClick = () => {
    navigate('/landing-page');
  };
  return (
    <div className="h-100 pt-4">
      <Header />
      <div className="d-flex justify-content-center align-items-center flex-column .bg-warning gap-2 h-100">
        <h1>Not Authorised</h1>
        <div>Sorry, you do not have permission to access this page</div>
        <div>
          <BasicButton onClick={handleLandingPageClick}>
            Go to landing page
          </BasicButton>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
