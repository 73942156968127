import ReactDOM from 'react-dom';
export const DumpPointPopup2 = (
  coordinate,
  { title, data, flag, select = () => {} },
) => {
  const div = document.createElement('div');

  const popup = (
    <>
      {flag != 0 && title?.length > 0 && (
        <div>
          <div
            onClick={(v) => {
              select(v, true);
            }}
            className="mx-5 mt-2  "
            style={{
              textAlign: 'center',
              float: 'right',
              width: ' max-content',
              background: '#EBEBEB',
              height: '28px',
              font: 'normal normal 600 11px/27px Open Sans',
              borderRadius: '5px',
            }}
          >
            <span className=" px-3">{title}</span>{' '}
          </div>

          {/* <div className="mm-popup-tip-container"></div> */}
        </div>
      )}
    </>
  );

  ReactDOM.render(popup, div);
  return div;
};
