import React from 'react';

const ModalView = ({ onClose, backgroundColor, children, hide }) => {
  return (
    <div className="modal-view">
      <div className="modal-content-view rounded-2" style={{ backgroundColor }}>
        {!hide && (
          <div className="close-icon-view" onClick={onClose}>
            <b>&#x2715;</b>
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default ModalView;
