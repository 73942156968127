import { isEmpty, isNil } from 'lodash';
import { TruckData } from './types';

export const convertStringToNumArray = (str: string | null): number[] => {
  if (!str) return [];
  const numArray = str
    .split(',')
    .filter((value) => !isNaN(parseInt(value)))
    .map(Number);
  return numArray;
};

// Function to check if a truck matches the search query
export const isNameOrDepartmentMatch = (
  truck: TruckData,
  searchQuery?: string,
) => {
  if (!searchQuery) return true;
  return (
    truck.name.toLowerCase().includes(searchQuery) ||
    truck.departmentName?.toLowerCase().includes(searchQuery)
  );
};

// Function to check if a truck matches the category
export const isCategoryMatch = (truck: TruckData, categories: number[]) => {
  if (isEmpty(categories)) return true;
  return !isNil(truck.categoryCode) && categories?.includes(truck.categoryCode);
};
