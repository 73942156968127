import { Modal } from 'react-bootstrap';
import React, { useContext, useMemo, useState } from 'react';
import { UserContext } from '../../pages/service/userContext';
import { DateRangeSelector } from './DateRangeSelector';
import { emptyFilters } from '..';
import { UserStatus, UserType } from '../../pages/utils/constants';
import DropdownField from '../../pages/components/FormDropdown';
import BasicButton from '../../pages/components/BasicButton';

const UserFilterModal = (props) => {
  const { modalOpen, modalToggle, onSaveClick, initialFilteredData } = props;
  const [filteredData, setFilteredData] = useState(initialFilteredData);
  const { usersRestrictedDepartments, licenses, userInfo } =
    useContext(UserContext);

  const filterFields = useMemo(
    () => [
      {
        name: 'User type',
        key: 'user_type',
        options:
          userInfo.type === UserType.admin.value
            ? Object.values(UserType)
            : Object.values(UserType).filter(
                ({ value }) => value !== UserType.admin.value,
              ),
      },
      {
        name: 'Status',
        key: 'status',
        options: Object.values(UserStatus).map((status) => ({
          value: status,
          name: status,
        })),
      },
      {
        name: 'Department',
        key: 'departments',
        options: usersRestrictedDepartments.map(({ name, code }) => ({
          name,
          value: code,
        })),
      },
      {
        name: 'Equipment License',
        key: 'licenses',
        options: licenses.map(({ name, code }) => ({
          name,
          value: code,
        })),
      },
    ],
    [licenses, usersRestrictedDepartments, userInfo],
  );

  const onCheckboxClick = (fieldName, value, multiselect) => {
    if (multiselect) {
      setFilteredData((prev) => {
        const filters = { ...prev };
        if (filters[fieldName].includes(value))
          filters[fieldName] = filters[fieldName].filter(
            (selectedValue) => selectedValue !== value,
          );
        else filters[fieldName] = [...filters[fieldName], value];
        return filters;
      });
    } else {
      setFilteredData((prev) => ({ ...prev, [fieldName]: value }));
    }
  };

  const clearFilter = () => {
    setFilteredData(emptyFilters);
    onSaveClick(emptyFilters);
  };

  const setDateRange = (dateRange) => {
    const dateFilters = { startDate: '', endDate: '' };
    if (dateRange?.from) dateFilters.startDate = dateRange.from;
    if (dateRange?.to) dateFilters.endDate = dateRange.to;
    setFilteredData({ ...filteredData, ...dateFilters });
  };

  const saveHandler = () => {
    const copyFilteredData = {
      ...filteredData,
      startDate: filteredData.startDate || undefined,
      endDate: filteredData.endDate || undefined,
    };
    onSaveClick(copyFilteredData);
    setFilteredData({ ...filteredData });
  };

  return (
    <div>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={modalToggle}
          backdrop="static"
          keyboard={false}
          size="lg"
          dialogClassName="modal-position"
        >
          <Modal.Header>
            <div className="d-flex justify-content-between w-100 align-items-center ms-4 me-2 my-3 font-13">
              <b className="ps-1">Filter by</b>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => modalToggle(false)}
              ></button>
            </div>
          </Modal.Header>
          <div className="pt-0 font-13 d-flex flex-column justify-content-between h-100">
            <div className="mx-4 px-2 d-flex flex-column gap-2">
              <DateRangeSelector
                dateRange={{
                  from: filteredData.startDate,
                  to: filteredData.endDate,
                }}
                setDateRange={setDateRange}
              />
              {filterFields.map(({ name, options, key }) => (
                <DropdownField
                  name={name}
                  key={name}
                  fieldKey={key}
                  selectedOptions={filteredData[key]}
                  allOptions={options}
                  multiselect
                  upperLevelClassName="d-flex flex-row justify-content-between"
                  handleChange={onCheckboxClick}
                />
              ))}
            </div>
            <div className="d-flex align-items-center justify-content-between pt-3 p-4 border-top bg-light">
              <button
                className="border border-0 bg-transparent"
                onClick={clearFilter}
              >
                <u>Clear all</u>
              </button>
              <BasicButton onClick={saveHandler} variant="dark">
                Apply
              </BasicButton>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UserFilterModal;
