import { FunctionComponent } from 'react';
export enum TruckStatus {
  active = 'active',
  inactive = 'inactive',
}

type TruckContainerProps = {
  image: string;
  status: TruckStatus;
};
const TruckContainer: FunctionComponent<TruckContainerProps> = ({
  image,
  status,
}: TruckContainerProps) => {
  return (
    <div
      className={`truck-image-container ${
        status === TruckStatus.active
          ? 'active-truck-color'
          : 'inactive-truck-color'
      } `}
    >
      <img src={image} className="truck-image-size" width={167} height={80} />
    </div>
  );
};

export default TruckContainer;
