import { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import ActionIcon from '../assets/Actions.png';
import { UserStatus, UserType } from '../utils/constants';

const CustomToggle = forwardRef(({ onClick }, ref) => (
  <img
    className="image-click"
    alt="action button"
    src={ActionIcon}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  />
));

export const dropdownOptions = {
  view: 'view',
  edit: 'edit',
  changestatus: 'changeStatus',
  delete: 'delete',
  newPin: 'newPin',
  resendInvite: 'resendInvite',
};

export const UserActionDropdown = ({ selectedUser, handleDropdownOptions }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu>
        {selectedUser.status !== UserStatus.invited && (
          <Dropdown.Item
            onClick={() =>
              handleDropdownOptions(dropdownOptions.changestatus, selectedUser)
            }
          >
            <span className="dropdown-text">
              {selectedUser.status === UserStatus.inactive
                ? 'Activate'
                : 'Deactivate'}
            </span>
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={() =>
            handleDropdownOptions(dropdownOptions.edit, selectedUser)
          }
        >
          <span className="dropdown-text">Edit</span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleDropdownOptions(dropdownOptions.delete, selectedUser);
          }}
        >
          <span className="dropdown-text">Delete</span>
        </Dropdown.Item>
        {selectedUser.user_type !== UserType.admin.value && (
          <Dropdown.Item
            onClick={() => {
              handleDropdownOptions(dropdownOptions.newPin, selectedUser);
            }}
          >
            <span className="dropdown-text">Generate New Pin</span>
          </Dropdown.Item>
        )}
        {selectedUser.status === 'Invited' && (
          <Dropdown.Item
            onClick={() => {
              handleDropdownOptions(dropdownOptions.resendInvite, selectedUser);
            }}
          >
            <span className="dropdown-text">Resend Invitation</span>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
