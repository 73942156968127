import { FunctionComponent } from 'react';

import { PrestartRowProps } from './types';
import {
  getLocalTime,
  getStatusName,
  getStyleOfCellByStatus,
  getStyleOfSelectedRow,
  getSupervisorSignature,
  isPrestartComplete,
} from '../../helper';
import { SortBy } from '../../models/enums';

export const PrestartRow: FunctionComponent<PrestartRowProps> = (props) => {
  const onClick = (): void => {
    if (isPrestartComplete(props.prestartCheck)) {
      props.onClick(props.prestartCheck._id);
    }
  };

  return (
    <tr
      onClick={onClick}
      style={getStyleOfSelectedRow(props.isSelected, props.prestartCheck)}
    >
      <td>{props.prestartCheck.assetID}</td>

      {props.sortBy === SortBy.CATEGORY ? (
        <td>{props.prestartCheck.department}</td>
      ) : null}

      <td style={getStyleOfCellByStatus(props.prestartCheck)}>
        <strong>{getStatusName(props.prestartCheck)}</strong>
      </td>
      <td>{props.prestartCheck.refNo || '-'}</td>

      <td>
        {props.prestartCheck.operator.first_name}{' '}
        {props.prestartCheck.operator.last_name}
      </td>
      <td
        style={{
          color: isPrestartComplete(props.prestartCheck) ? '' : '#707070',
        }}
      >
        {getLocalTime(props.prestartCheck.loginTime)}
      </td>
      <td>
        {props.prestartCheck.checkTime
          ? getLocalTime(props.prestartCheck.checkTime)
          : '-'}
      </td>

      <td>
        {isPrestartComplete(props.prestartCheck)
          ? props.prestartCheck.failedChecks
          : '-'}
      </td>

      <td>
        {props.prestartCheck.engineHours === 0
          ? '0000'
          : props.prestartCheck.engineHours}{' '}
        hrs
      </td>
      <td>{getSupervisorSignature(props.prestartCheck)}</td>
    </tr>
  );
};
