import React, { useMemo, useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { getTime, getZones } from '../../utils/helper';
import Header from './header';
import { GET_SOURCE_HISTORY } from '../../action/apiPath';
import moment from 'moment';
import { CallWithAuth } from '../../action/apiActions';

export const groupJob = (list) => {
  if (!list) return {};
  return list?.reduce((prev, job) => {
    const key = job.type ? 'Unscheduled' : 'Scheduled';
    if (!prev[key]) prev[key] = [job];
    else prev[key].push(job);
    return prev;
  }, {});
};

const JobTable = ({ groupedJobs, filteredZones }) => (
  <Table id="pdf-table">
    <thead className="table-head">
      <tr>
        <th className="ref-no-td">Ref No</th>
        <th>Location</th>
        <th>Truck</th>
        <th className="duration-td">Job Duration</th>
        <th>Pump Activity</th>
        <th className="water-td">Water Consumed</th>
        <th>Operator</th>
      </tr>
    </thead>
    <tbody>
      {groupedJobs.map((job, index) => (
        <tr key={index}>
          <td>{job.type === 'unscheduled' ? '-' : job?.number || '-'}</td>
          <td>{getZones(job, filteredZones)}</td>
          <td>{job?.truck.name}</td>
          <td>
            {job.type === 'unscheduled' ? (
              '-'
            ) : (
              <>
                <div>
                  {getTime(job?.start_time)} -{' '}
                  {job?.end_time ? getTime(job?.end_time) : 'Ongoing'}
                </div>
                <div>({formatTime(job?.jobMinutes)})</div>
              </>
            )}
          </td>
          <td>{formatTime(job?.pumpMinutes)}</td>
          <td>{job?.litres + ' L'}</td>
          <td>
            {(job?.operator?.first_name ?? '-') +
              ' ' +
              (job?.operator?.last_name ?? '')}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export const formatTime = (minutes) => {
  if (!minutes) return '00:00 h';
  const onlyMinutes = (minutes % 60).toString();
  const hours = ((minutes - onlyMinutes) / 60).toString();
  return `${hours.padStart(2, '0')}:${onlyMinutes.padStart(2, '0')} h`;
};

function JobDailyReport({ jobs, date, filteredZones, downloadFields }) {
  const [historyData, setHistoryData] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchDumpPoints = async () => {
      try {
        let response = await CallWithAuth(
          'GET',
          `${GET_SOURCE_HISTORY}?startDate=${
            downloadFields?.length > 0
              ? downloadFields[0]?.value?.startDate
              : ''
          }&endDate=${
            downloadFields?.length > 0 ? downloadFields[0]?.value?.endDate : ''
          }`,
        );

        console.log(response, 'reponse');
        if (response?.res?.data?.status === 200) {
          setHistoryData(Object.keys(response?.res?.data?.data));

          setData(response?.res?.data?.data);
        } else throw response?.res;
      } catch (error) {
        console.log(error);
      }
    };
    fetchDumpPoints();
  }, []);

  const groupedJobs = groupJob(jobs);

  let biggestLitres = 10000;
  Object.values(data).forEach((val) => {
    if (val && typeof val === 'object' && 'waterUsage' in val) {
      if (val.waterUsage > biggestLitres) {
        biggestLitres = val.waterUsage;
      }
    }
  });

  const tableRows = useMemo(
    () => [
      {
        key: 'time',
        name: 'time',
        child: (dataValue) => (
          <>{moment(dataValue?.loadtime).format('hh:mm a ')}</>
        ),
      },
      {
        key: 'truck',
        name: 'truck',
        child: (dataValue) => (
          <>
            {dataValue.truck}/ <br /> {dataValue.truckOperator}
          </>
        ),
      },
      {
        key: 'material',
        name: 'material',
        child: (dataValue) => <div>{dataValue.material}</div>,
      },
      {
        key: 'escavator',
        name: 'escavator',
        child: (dataValue) => (
          <div>
            {dataValue.fromexc}/ <br /> {dataValue.ex_operator}
          </div>
        ),
      },

      {
        key: 'dumppoint',
        name: 'dumppoint',
        child: (dataValue) => <div>{dataValue.dumpPoint}</div>,
      },

      {
        key: 'dumptime',
        name: 'dumptime',
        child: (dataValue) => (
          <>{moment(dataValue?.dumpTime).format('hh:mm a ')}</>
        ),
      },
    ],
    [],
  );

  return (
    <div className="pdf-view" id="print-this-div">
      <Header data={data.date} date={date} endDate={data.endDate} />

      <div className="truck-details">
        <div className="d-flex">
          <div className="col-3">Source Points:</div>
          <div>
            <b>Source Points</b>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-3">Dump Points: </div>
          <div>
            <b>Dump Points</b>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-3">Trucks: </div>
          <div>
            <b>All </b>
          </div>
        </div>

        <div className="d-flex">
          <div className="col-3">Excavators: </div>
          <div>
            <b>All</b>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-3">Operators :</div>
          <div>
            <b>All</b>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-3">Material: </div>
          <div>
            <b>All</b>
          </div>
        </div>
      </div>

      <div className="job-report-heading">Zone Breakdown</div>
      <Table id="pdf-table">
        <thead className="table-head">
          <tr>
            <th>Time</th>

            <th>
              <div>Truck/</div>
              <div>Operator</div>
            </th>
            <th>Material</th>

            <th>
              <div>Excavator/</div>
              <div>Operator</div>
            </th>

            <th>Dump Point</th>
            <th>Dump Time </th>
          </tr>
        </thead>
        <tbody>
          {historyData?.map((value, index) => (
            <>
              <tr className="table-head " key={index}>
                <td>
                  {value} {tableRows.length}
                </td>
                {tableRows?.map(({ key, child }) => (
                  <td align="left" valign="top" key={key}></td>
                ))}
              </tr>
              {data[value]?.map((userValue, userIndex) => (
                <tr className="table-head " key={userIndex}>
                  {tableRows?.map(({ key, child }) => (
                    <td align="left" valign="top" key={key}>
                      {child(userValue)}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default JobDailyReport;
