import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  getIdleTimePercentage,
  getInactiveTimePercentage,
  getTransmissionPercentage,
} from './helper';
import { BulldozerSummary, TruckCategory } from '../../../models';
import styles from '../Summary.module.css';
import messages from '../../../constants/messages';
import DonutProgress from '../../../pages/components/PieChart/Donut/DonutProgress';
import colours from '../../../constants/colours';
import { isUndefined, upperFirst } from 'lodash';
import { DateRange } from 'react-day-picker';
import { useAssetContext } from '../../../pages/service/assetContext';
import {
  convertMinutesToHHMM,
  formatAsNDigitNumber,
  getFirstAndLastDayOfMonth,
  subtractOneMonth,
} from '../../helper';
import { getEngineHours } from '../../services';
import { roundOff2, subtractDays } from '../../timeline/helper';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxHeightBreakPoint } from '../../constants';
import Engine from '../../assets/Engine';
import Fuel from '../../assets/Fuel';
import { AuthContext } from '../../../pages/service/auth';
import { SelectedAsset } from '../../model';

type UtilizationSummaryProps = {
  truckCategory: TruckCategory;
  range: DateRange | undefined;
  data: BulldozerSummary;
};

const UtilizationSummary: FunctionComponent<UtilizationSummaryProps> = (
  props: UtilizationSummaryProps,
) => {
  const transmissionPercentage: number = getTransmissionPercentage(props.data);
  const idleTimePercentage: number = getIdleTimePercentage(props.data);
  const inactiveTimePercentage: number = getInactiveTimePercentage(props.data);
  const [engineHourMacro, setEngineHourMacro] = useState<number>(0);
  const { selectedAssets } = useAssetContext();

  const abortControllers = useRef(new Map<string, AbortController>());

  const createAbortController = (key: string): AbortController => {
    const abortController = new AbortController();
    abortControllers.current.set(key, abortController);
    return abortController;
  };

  const abortPreviousRequests = () => {
    abortControllers.current.forEach((controller) => controller.abort());
    abortControllers.current.clear();
  };

  const { logOut } = useContext(AuthContext);

  const fetchData = async (): Promise<void> => {
    if (props.range?.from && props.range?.to) {
      const newRange: DateRange = getFirstAndLastDayOfMonth(
        subtractOneMonth(props.range.from),
      );
      const fetch = async () => {
        try {
          abortPreviousRequests(); // Abort any previous requests
          setEngineHourMacro(0); //It stores the comparison data i.e. Less/More than yesterday/last month
          let engineHours: number = 0;
          // Fetch data for each selected asset
          await Promise.all(
            selectedAssets.map(async (asset: SelectedAsset) => {
              const engineHoursData:
                | {
                    deviceId: string;
                    truckName: string;
                    calculatedEngineHours: number;
                    calculatedEngineMinutes: number;
                    totalEngineHoursInMinutes: number;
                    currentEngineStatus: string;
                    lastUpdatedTime: string;
                  }
                | undefined = await getEngineHours(
                newRange,
                asset.name,
                createAbortController('engineHours').signal,
                logOut,
              );

              // Aggregate engineHours
              engineHours += engineHoursData?.calculatedEngineMinutes ?? 0;
            }),
          );
          setEngineHourMacro(
            !isUndefined(engineHours)
              ? props.data.engineHours - engineHours
              : props.data.engineHours,
          );
        } catch (error) {
          console.error(error);
        }
      };
      fetch();
    } else if (props.range?.from) {
      const newRange: DateRange = {
        from: subtractDays(props.range.from, 1),
        to: undefined,
      };
      const fetch = async () => {
        try {
          abortPreviousRequests(); // Abort any previous requests
          setEngineHourMacro(0);
          let engineHours: number = 0;
          // Fetch data for each selected asset
          await Promise.all(
            selectedAssets.map(async (asset: SelectedAsset) => {
              const engineHoursData:
                | {
                    deviceId: string;
                    truckName: string;
                    calculatedEngineHours: number;
                    calculatedEngineMinutes: number;
                    totalEngineHoursInMinutes: number;
                    currentEngineStatus: string;
                    lastUpdatedTime: string;
                  }
                | undefined = await getEngineHours(
                newRange,
                asset.name,
                createAbortController('engineHours').signal,
                logOut,
              );

              // Aggregate engineHours
              engineHours += engineHoursData?.calculatedEngineMinutes ?? 0;
            }),
          );
          setEngineHourMacro(
            !isUndefined(engineHours)
              ? props.data.engineHours - engineHours
              : props.data.engineHours,
          );
        } catch (error) {
          console.error(error);
        }
      };
      fetch();
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      abortPreviousRequests(); // Abort requests on component unmount
    };
  }, [props.range, selectedAssets]);

  useEffect(() => {
    const interval: NodeJS.Timeout = setInterval(
      () => {
        fetchData();
      },
      Number(process.env.REACT_APP_API_REFRESH_INTERVAL_IN_MINUTES ?? 5) *
        60 *
        1000,
    );
    return () => {
      clearInterval(interval);
    };
  }, [props.range, selectedAssets]);

  // useEffect(() => {
  //   const interval = setInterval(
  //     () => {
  //       fetchData();
  //     },
  //     Number(process.env.REACT_APP_API_REFRESH_INTERVAL_IN_MINUTES ?? 5) *
  //       60 *
  //       1000,
  //   );
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [props.range, selectedAsset]);

  const { height } = useMediaQuery();

  return (
    <div className={styles.sectionContainer}>
      <div className={styles['summary-section-title']}>The Engine</div>

      <section className={styles['sections-container']}>
        <div id="active" className={styles.pushes}>
          <DonutProgress
            values={{
              primary: transmissionPercentage >= 0 ? transmissionPercentage : 0,
              secondary: idleTimePercentage >= 0 ? idleTimePercentage : 0,
              tertiary:
                isNaN(inactiveTimePercentage) ||
                (inactiveTimePercentage === 0 &&
                  transmissionPercentage === 0 &&
                  idleTimePercentage === 0)
                  ? 100
                  : inactiveTimePercentage,
            }}
            colorScheme={{
              primary: colours.darkBlue,
              secondary: colours.lightBlue,
              tertiary: colours.lightGray,
            }}
            textContent={`Utilisation`}
            inShadow
            small={height <= maxHeightBreakPoint}
          />
          <span className={`${styles['summary-message-key']}`}>
            {engineHourMacro >= 0 ? '+' : '-'}
            {convertMinutesToHHMM(Math.abs(roundOff2(engineHourMacro)))} h{' '}
            {messages.DMU.DASHBOARD.ACTIVE}{' '}
            {props.range && props.range.to === undefined
              ? messages.DMU.DASHBOARD.THAN_PREVIOUS_DAY
              : messages.DMU.DASHBOARD.THAN_PREVIOUS_MONTH}
          </span>
        </div>
        <div
          className={styles['non-chart-content']}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <div className={styles.metric}>
              <div
                className={styles.colorCode}
                style={{ background: colours.darkBlue }}
              ></div>
              <div className={styles.valueBox}>
                <div className={styles.value}>
                  {Math.round(transmissionPercentage) >= 0
                    ? Math.round(transmissionPercentage)
                    : '0'}{' '}
                  %
                </div>
                <div className={styles.val2}>{`(${
                  convertMinutesToHHMM(props.data.transmissionTime) ?? '00:00'
                } h)`}</div>
              </div>
            </div>
            <div className={styles.metric}>
              <div
                className={styles.colorCode}
                style={{ background: colours.lightBlue }}
              ></div>
              <div className={styles.valueBox}>
                <div className={styles.value}>
                  {Math.round(idleTimePercentage) >= 0
                    ? Math.round(idleTimePercentage)
                    : '0'}{' '}
                  %
                </div>

                <div className={styles.val2}>{`(${
                  convertMinutesToHHMM(props.data.idleTime) ?? '00:00'
                } h)`}</div>
              </div>
            </div>
            <div className={styles.metric}>
              <div
                className={styles.colorCode}
                style={{ background: colours.lightGray }}
              ></div>
              <div className={styles.valueBox}>
                <div className={styles.value}>
                  {isNaN(inactiveTimePercentage) ||
                  (inactiveTimePercentage === 0 &&
                    transmissionPercentage === 0 &&
                    idleTimePercentage === 0)
                    ? 100
                    : Math.round(inactiveTimePercentage)}{' '}
                  %
                </div>

                <div className={styles.val2}>{`(${
                  convertMinutesToHHMM(props.data.inactiveTime) ?? '00:00'
                } h)`}</div>
              </div>
            </div>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}
          >
            <div className={styles.subText} style={{ paddingTop: '4px' }}>
              {upperFirst(messages.DMU.DASHBOARD.TRANSMISSION_TIME)}
            </div>
            <div className={styles.subText} style={{ paddingTop: '4px' }}>
              {upperFirst(messages.DMU.DASHBOARD.IDLE_TIME)}
            </div>
            <div className={styles.subText} style={{ paddingTop: '4px' }}>
              {upperFirst(messages.DMU.DASHBOARD.INACTIVE_TIME)}
            </div>
          </div>
        </div>
        <div className={styles.verticalLine} style={{ margin: '0px 10px' }} />
        <div
          className={styles['non-chart-content']}
          style={{ justifyContent: 'space-between' }}
        >
          <div className={styles.metric}>
            <div>
              <Engine />
            </div>
            <div className={styles.valueBox}>
              <div className={styles.valueBox}>
                <div className={styles.val1}>
                  {messages.DMU.DASHBOARD.ENGINE_HOURS}
                </div>
                <div className={styles.subText2}>
                  {`(Transmission + idle time)`}
                </div>
                <div className={styles.value} style={{ fontWeight: 'bold' }}>
                  {convertMinutesToHHMM(props.data.engineHours) ?? '00:00'} h
                </div>
                <div className={styles.subText2}>
                  {engineHourMacro >= 0 ? '+' : '-'}
                  {convertMinutesToHHMM(Math.abs(engineHourMacro)) ?? '00:00'}
                  {' h '}{' '}
                  {props.range && props.range.to === undefined
                    ? messages.DMU.DASHBOARD.THAN_PREVIOUS_DAY
                    : messages.DMU.DASHBOARD.THAN_PREVIOUS_MONTH}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.valueBox} style={{ paddingLeft: '30px' }}>
            <div className={`${styles.val1}`}>
              {messages.DMU.DASHBOARD.TOTAL}{' '}
              {messages.DMU.DASHBOARD.MACHINE_HOURS}
            </div>
            <div className={`${styles.val3}`}>
              {formatAsNDigitNumber(props.data.totalEngineHours ?? 0, 2)} h
            </div>
          </div>
        </div>
        <div className={styles['non-chart-content']}>
          <div className={styles.metric}>
            <div>
              <Fuel />
            </div>
            <div className={styles.valueBox}>
              <div className={styles.val1}>{messages.DMU.DASHBOARD.FUEL}</div>
              <div
                className={styles.value}
                style={{ fontWeight: 'bold', marginTop: '18px' }}
              >
                {props.data.fuel} l
              </div>
              <div className={styles.subText2}>
                000 l -/+{' '}
                {props.range && props.range.to === undefined
                  ? messages.DMU.DASHBOARD.THAN_PREVIOUS_DAY
                  : messages.DMU.DASHBOARD.THAN_PREVIOUS_MONTH}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UtilizationSummary;
