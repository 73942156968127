import 'react-toastify/dist/ReactToastify.css';
import './pages/assets/styles/app.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AssetTracking } from '@optitrac/components';
import ManageUser from './manageUser';
import Login from './pages/Login';
import { UserContext, UserState } from './pages/service/userContext';
import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  ProtectRoute,
  RedirectToLandingPage,
} from './pages/service/protectRoute';
import PreStartChecks from './preStartChecks';
import MaterialMovements from './materialMovements/index';
import Summary from './preStartChecks/Summary';
import Report1 from './preStartChecks/Components/reports/report-1';
import Spinners from './pages/components/spinner';
import { NZSTEEL_API_BASE_URL } from './pages/utils/constants';
import DmuDashboard from './dmu';
import Maintenance from './maintenance';
import { GlobalContext, GlobalProvider } from './pages/service/GlobalContext';
import { withAccessControl } from './pages/service/with-access-control';
import ErrorBoundary from './materialMovements/ErrorBoundary';
import { SocketProvider } from './pages/utils/socket';
import { CallWithAuth } from './materialMovements/action/apiActions';
import { getAuthToken } from './dmu/services';
import { WebSocketURL } from './hooks/useSocket';
import Kpi from './kpi/kpi';

const Unauthorized = React.lazy(() => import('./pages/unauthorized'));
const PasswordChanged = React.lazy(
  () => import('./pages/profile/set-password/password-set-message'),
);
const InvalidLink = React.lazy(
  () => import('./pages/profile/set-password/Invalid-password-reset-link'),
);
const SetPassword = React.lazy(() => import('./pages/profile/set-password'));
const LandingPage = React.lazy(() => import('./pages/landingPage'));
const ForgotPassword = React.lazy(
  () => import('./pages/profile/ForgotPassword'),
);

const AppRoutes = () => {
  return (
    <>
      <div style={{ zIndex: 1, height: '100%' }}>
        <Suspense fallback={<Spinners />}>
          <Routes>
            {/* protected routes -- must be logged in */}
            <Route element={<ProtectRoute />}>
              <Route path="/landing-page" element={<LandingPage />}></Route>
              <Route
                path="/user-management"
                element={<ManageUser code={700} />}
              />
              <Route
                path="/pre-start-check"
                element={<PreStartChecks code={600} />}
              />
              <Route
                path="/material-movements"
                element={<MaterialMovements code={200} />}
              />
              <Route
                path="/asset-tracking"
                element={
                  <ErrorBoundary>
                    <AssetTracking
                      code={800}
                      GlobalContext={GlobalContext}
                      UserContext={UserContext}
                      withAccessControl={withAccessControl}
                      useNavigate={useNavigate}
                      useLocation={useLocation}
                      CallWithAuth={CallWithAuth}
                      publicLambdaBaseUrl={NZSTEEL_API_BASE_URL}
                      getAccessToken={getAuthToken}
                      websocketBaseUrl={WebSocketURL}
                      mapCenter={{ lat: -37.352101, long: 174.721 }}
                    />
                  </ErrorBoundary>
                }
              />
              <Route path="/dmu" element={<DmuDashboard code={400} />} />
              <Route path="/maintenance" element={<Maintenance code={300} />} />

              {/* Routes used by backend to generate PDFs */}
              <Route path="/summary" element={<Summary />} />
              {/* @ts-expect-error props will be undefined when navigating from Route, because they will be passed in as query params */}
              <Route path="/prestart-report" element={<Report1 />} />

              <Route path="/kpi" element={<Kpi code={500} />} />

              <Route
                path="*"
                element={
                  <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                    <h1 className="display-1">404</h1>
                    <h2>Ooops, page not found!</h2>
                    <Link to="/landing-page">Go back</Link>
                  </div>
                }
              />
            </Route>

            {/* protected routes -- must not be logged in */}
            <Route element={<RedirectToLandingPage />}>
              <Route path="/" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>

            {/* public routes */}
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/password-changed" element={<PasswordChanged />} />
            <Route path="/invalid-link" element={<InvalidLink />} />
          </Routes>
        </Suspense>
      </div>
    </>
  );
};

const App = () => {
  return (
    <div className="app">
      <SocketProvider>
        <UserState>
          <GlobalProvider>
            <AppRoutes />
          </GlobalProvider>
        </UserState>
      </SocketProvider>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
