import React from 'react';

const StatusDot = (props) => {
  const { children, className, large, color } = props;
  return (
    <div className="d-flex flex-row align-items-center">
      <span
        style={{ background: color ? color : '' }}
        className={`status-dot ${large ? 'large' : 'small'} ${className}`}
      ></span>
      {children}
    </div>
  );
};

export default StatusDot;
