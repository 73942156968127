import BasicButton from '../BasicButton';
import Dropdown from '../../assets/icons/dropdown.png';
import { useCallback, useEffect, useState } from 'react';

const DownloadModal = ({
  type,
  onCreate,
  onCancel,
  downloading,
  selectedDate,
  setSelectedDate,
  downloadFields = [{}],
  setDownloadFields,
  dateChanged,
}) => {
  const [displayedDropdown, setDisplayedDropdown] = useState();

  const zoneOptions = useCallback(
    () => [
      {
        name: 'Select date range',
        field: 'dateRange',
        type: 'dateRange',
        value: {
          startDate: '2023-07-12',
          endDate: '2023-07-12',
        },
      },
      {
        name: 'Zones',
        field: 'zones',
        type: 'select',
        options: [
          {
            value: 1,
            displayedValue: 'Zone 1',
            name: 'zone',
          },
          {
            value: 2,
            displayedValue: 'Zone 2',
            name: 'zone',
          },
          {
            value: 3,
            displayedValue: 'Zone 3',
            name: 'zone',
          },
          {
            value: 4,
            displayedValue: 'Zone 4',
            name: 'zone',
          },
          {
            value: 5,
            displayedValue: 'Zone 5',
            name: 'zone',
          },
          {
            value: 6,
            displayedValue: 'Zone 6',
            name: 'zone',
          },
        ],
        value: [],
      },
      {
        name: 'Trucks',
        field: 'trucks',
        type: 'select',
        options: [
          {
            value: '627',
            name: 'truck',
          },
          {
            value: '721',
            name: 'truck',
          },
          {
            value: '819',
            name: 'truck',
          },
        ],
        value: [],
      },
    ],
    [],
  );

  const jobHistoryOptions = useCallback(
    () => [
      {
        name: 'Select date range',
        field: 'dateRange',
        type: 'dateRange',
        value: {
          startDate: selectedDate,
          endDate: selectedDate,
        },
      },
      {
        name: 'Zones',
        field: 'zones',
        type: 'select',
        options: [
          {
            value: 1,
            displayedValue: 'Zone 1',
            name: 'zone',
          },
          {
            value: 2,
            displayedValue: 'Zone 2',
            name: 'zone',
          },
          {
            value: 3,
            displayedValue: 'Zone 3',
            name: 'zone',
          },
          {
            value: 4,
            displayedValue: 'Zone 4',
            name: 'zone',
          },
          {
            value: 5,
            displayedValue: 'Zone 5',
            name: 'zone',
          },
          {
            value: 6,
            displayedValue: 'Zone 6',
            name: 'zone',
          },
        ],
        value: [],
      },
      {
        name: 'Trucks',
        field: 'trucks',
        type: 'select',
        options: [
          {
            value: '627',
            name: 'truck',
          },
          {
            value: '721',
            name: 'truck',
          },
          {
            value: '819',
            name: 'truck',
          },
        ],
        value: [],
      },
      {
        name: 'Status',
        field: 'status',
        type: 'select',
        options: [
          {
            value: 'complete',
            displayedValue: 'Complete',
            name: 'status',
          },
          {
            value: 'incomplete',
            displayedValue: 'Incomplete',
            name: 'status',
          },
        ],
        value: [],
      },
    ],
    [],
  );

  const waterTowerOptions = useCallback(
    () => [
      {
        name: 'Select date range',
        field: 'dateRange',
        type: 'dateRange',
        value: {
          startDate: '',
          endDate: '',
        },
      },
      {
        name: 'Job Ref No.',
        field: 'number',
        type: 'text',
        value: '',
      },
      {
        name: 'Trucks',
        field: 'trucks',
        type: 'select',
        options: [
          {
            value: '627',
            name: 'truck',
          },
          {
            value: '721',
            name: 'truck',
          },
          {
            value: '819',
            name: 'truck',
          },
        ],
        value: [],
      },
      {
        name: 'Tower',
        field: 'tower',
        type: 'select',
        options: [
          {
            value: 1,
            name: 'tower',
          },
          {
            value: 2,
            name: 'tower',
          },
        ],
        value: [],
      },
    ],
    [],
  );

  useEffect(() => {
    if (type === 'zone') {
      setDownloadFields(zoneOptions());
    }
    if (type === 'job') {
      setDownloadFields(jobHistoryOptions());
    }
    if (type === 'waterTower') {
      setDownloadFields(waterTowerOptions());
    }
  }, [type, zoneOptions, jobHistoryOptions, waterTowerOptions]);

  //   useEffect(() => {
  //  let obj = {
  //     "name": "Select date range",
  //     "field": "dateRange",
  //     "type": "dateRange",
  //     "value": {
  //         "startDate": "",
  //         "endDate": ""
  //     }
  // }
  //     obj.value.endDate = new Date(Date.now()).toISOString().slice(0, 10);
  //     obj.value.startDate = new Date(Date.now()).toISOString().slice(0, 10);
  //     setDownloadFields([obj]);
  //   }, [ ]);

  const handleDateBtns = (value) => {
    console.log({ downloadFields });

    let obj = [...downloadFields];
    console.log(obj);
    obj[0].value.endDate = new Date(Date.now()).toISOString().slice(0, 10);
    if (value === 'today') {
      obj[0].value.startDate = new Date(Date.now()).toISOString().slice(0, 10);
    } else if (value === 'yesterday') {
      obj[0].value.startDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10);
      obj[0].value.endDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10);
    } else {
      obj[0].value.startDate = new Date(
        Date.now() - value * 24 * 60 * 60 * 1000,
      )
        .toISOString()
        .slice(0, 10);
    }
    setDownloadFields(obj);
  };

  const handleDate = (event, type) => {
    const obj = [...downloadFields];
    if (type === 'start') {
      if (
        new Date(event.target.value) <= new Date(Date.now()) &&
        new Date(event.target.value) <=
          new Date(obj[0].value.endDate || Date.now())
      ) {
        obj[0].value.startDate = event.target.value;
        console.log({ e: event.target.value });
      } else obj[0].value.startDate = '';
    } else {
      if (
        new Date(event.target.value) <= new Date(Date.now()) &&
        (obj[0].value.startDate
          ? new Date(event.target.value) >= new Date(obj[0].value.startDate)
          : true)
      )
        obj[0].value.endDate = event.target.value;
      else obj[0].value.endDate = '';
    }
    setDownloadFields(obj);
  };

  useEffect(() => {
    if (dateChanged) {
      handleDateBtns('today');
    }
  }, []);
  const handleSelect = (value, index) => {
    const obj = [...downloadFields];
    if (!obj[index].value.includes(value)) {
      obj[index].value.push(value);
    } else {
      obj[index].value = obj[index].value.filter((val) => val !== value);
    }
    setDownloadFields(obj);
  };

  const handleSearch = (value, index) => {
    let tempDownloadObj = [];
    if (type === 'zone') {
      tempDownloadObj = zoneOptions();
    } else if (type === 'job') {
      tempDownloadObj = jobHistoryOptions();
    } else if (type === 'waterTower') {
      tempDownloadObj = waterTowerOptions();
    }
    if (!value) {
      setDownloadFields([...tempDownloadObj]);
    } else {
      let options = tempDownloadObj[index].options.filter((opt) =>
        opt.value.toString().toLowerCase().includes(value.toLowerCase()),
      );
      tempDownloadObj[index].options = options;
      setDownloadFields([...tempDownloadObj]);
    }
  };

  const handleTextSearch = (value, index) => {
    const newFields = downloadFields.map((field, fieldIndex) => {
      if (index === fieldIndex) return { ...field, value };
      else return field;
    });
    setDownloadFields(newFields);
  };

  const handleShowSelectOptions = (name) => {
    if (displayedDropdown === name) setDisplayedDropdown(undefined);
    else setDisplayedDropdown(name);
  };

  const onCreateFunc = () => {
    const payload = {};
    downloadFields.forEach((field, index) => {
      if (field.type === 'dateRange') {
        payload.startDate = downloadFields[index].value.startDate
          ? new Date(downloadFields[index].value.startDate).toISOString()
          : new Date(Date.now()).toISOString();
        payload.endDate = downloadFields[index].value.endDate
          ? new Date(downloadFields[index].value.endDate).toISOString()
          : new Date(Date.now()).toISOString();
      } else {
        payload[field.field] = field.value;
      }
    });
    onCreate(payload);
  };

  return (
    <div
      className="download-modal-container"
      style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: '14px' }}
    >
      <div
        className="download-modal-header mt-4 pb-3"
        style={{ font: 'normal normal bold 16px/22px Open Sans' }}
      >
        DOWNLOAD REPORT
      </div>
      <div className="download-modal-body">
        {downloadFields.map((field, index) => {
          return field.type === 'dateRange' ? (
            <div key={index} className="date-picker mb-2">
              <div
                className="py-1 bold-md"
                style={{ font: 'normal normal 600 12px/17px Open Sans' }}
              >
                Select date range
              </div>
              <div>
                <div
                  className="py-1 bold-md"
                  style={{ font: 'normal normal 600 10px/14px Open Sans' }}
                >
                  From
                </div>
                <div>
                  <input
                    className="date-picker-input"
                    style={{
                      border: '1px solid #EBEBEB',
                      padding: '0px 4px',
                      font: 'normal normal normal 12px/17px Open Sans',
                    }}
                    type="date"
                    name="from"
                    value={
                      field.value.startDate?.length > 1
                        ? field.value.startDate
                        : { selectedDate }
                    }
                    onChange={(e) => handleDate(e, 'start')}
                  />
                </div>
              </div>
              <div>
                <div
                  className="py-1 bold-md"
                  style={{ font: 'normal normal 600 10px/14px Open Sans' }}
                >
                  To
                </div>
                <div>
                  <input
                    style={{
                      border: '1px solid #EBEBEB',
                      padding: '0px 4px',
                      font: 'normal normal normal 12px/17px Open Sans',
                    }}
                    className="date-picker-input"
                    type="date"
                    name="to"
                    value={
                      field.value.startDate?.length > 1
                        ? field.value.endDate
                        : { selectedDate }
                    }
                    onChange={(e) => handleDate(e, 'end')}
                  />
                </div>
              </div>
              <div className="date-btns my-1">
                <div
                  style={{
                    border: '1px solid #EBEBEB',
                    background: '#FAFAFA',
                    color: '#707070',
                    font: 'normal normal 600 10px/14px Open Sans',
                  }}
                  onClick={() => handleDateBtns('today')}
                >
                  Today
                </div>
                <div
                  style={{
                    background: '#FAFAFA',
                    border: '1px solid #EBEBEB',
                    color: '#707070',
                    font: 'normal normal 600 10px/14px Open Sans',
                  }}
                  onClick={() => handleDateBtns('yesterday')}
                >
                  Yesterday
                </div>
                <div
                  style={{
                    background: '#FAFAFA',
                    border: '1px solid #EBEBEB',
                    color: '#707070',
                    font: 'normal normal 600 10px/14px Open Sans',
                  }}
                  onClick={() => handleDateBtns(7)}
                >
                  Last 7 days
                </div>
                <div
                  style={{
                    background: '#FAFAFA',
                    border: '1px solid #EBEBEB',
                    color: '#707070',
                    font: 'normal normal 600 10px/14px Open Sans',
                  }}
                  onClick={() => handleDateBtns(30)}
                >
                  Last 30 days
                </div>
              </div>
            </div>
          ) : null;
        })}

        <div className="mt-3 d-flex btn-container gap-2">
          <div className="mx-3">
            <BasicButton
              style={{
                border: '1px solid #2B2D2F',
                background: ' #FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: '4px',
                color: 'black',
              }}
              outlined={true}
              onClick={onCancel}
            >
              Cancel
            </BasicButton>
          </div>
          <div className="">
            <BasicButton
              disabled={downloading}
              outlined={true}
              style={{
                border: '1px solid black',
                background: '#2B2D2F 0% 0% no-repeat padding-box',
                color: 'white',
                borderRadius: '4px',
              }}
              onClick={onCreateFunc}
            >
              {downloading ? `Downloading...` : `Create`}
            </BasicButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
